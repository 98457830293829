<template>
    <div style="height: 100%;">
        <div id="Conversations" v-if="!selectedConversation">
            <v-subheader style="height: 40px;" class="px-2">
                <v-icon small class="mr-2">forum</v-icon>Conversations
            </v-subheader>
            <v-divider></v-divider>
            <v-list dense style="background: transparent !important;">
                <div v-if="primaryConversations.length > 0">
                    <v-list-item v-for="(convo, index) in primaryConversations" :key="index" @click="selectConvo(convo)">
                        <v-list-item-avatar>
                            <v-avatar>
                                <v-icon small :color="convo.type == 'Internal' ? 'greyBase' : 'orange'">account_tree</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content style="contain: content;">
                            <v-list-item-title>{{ convo.orderNo ?? '' }} - {{ convo.type }}</v-list-item-title>
                            <v-list-item-subtitle>{{ convo.messages.length > 0 ? `${convo.messages[convo.messages.length - 1].userId == $store.state.user.id ? 'You : ' : convo.messages[convo.messages.length - 1].user?.firstname ? convo.messages[convo.messages.length - 1].user.firstname + ': ' : '' } ${convo.messages[convo.messages.length - 1].comment}` : 'No Conversation' }}</v-list-item-subtitle>
                            <v-chip x-small class="px-1" style="position: absolute; right: 5px; top: 32%" v-if="convo.unreadTotal" color="tertiary">{{ convo.unreadTotal }}</v-chip>
                        </v-list-item-content>
                    </v-list-item>
                </div>
                <div v-if="conversations.length > 0">
                    <v-list-item v-for="(conversation, index) in conversations" :key="index">

                    </v-list-item>
                </div>
            </v-list>
        </div>
        <div id="Chat" v-if="selectedConversation">
            <v-subheader style="height: 40px;" class="px-2">
               <v-btn icon small class="mr-2" @click="exitConversation">
                    <v-icon small>arrow_back</v-icon>
                </v-btn>
                {{selectedConversation.orderNo ?? ''}} - {{selectedConversation.type}}
            </v-subheader>
            <v-divider></v-divider>
            <div id="ChatParent" class="chatParent">
                <div class="messages" id="chatList" :key="chatUpdateKey">
                    <v-row no-gutters v-for="(message, index) in structureMessages" :key="index"> 
                        <v-col cols="12" sm="12" md="12" lg="12" class="py-1 d-flex justify-center align-center" v-if="showDate(message, index)">
                            <v-chip small>{{ displayDate(message.createdAt) }}</v-chip>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" class="py-1 d-flex justify-center align-center" v-if="!structureMessages[index - 1]?.unread && message.unread">
                            <div style="height: 20px; width: 100%; background: #7f7f7f61;" class="d-flex align-center justify-center">
                                <b style="color: #d6d4d4; font-size: 0.75rem;">New Messages</b>
                            </div>
                        </v-col>
                        <v-col cols="12" :sm="message.type == 'received' ? '9' : '3'" :md="message.type == 'received' ? '9' : '3'" :lg="message.type == 'received' ? '9' : '3'" class="py-1" style="contain:content; height: min-content; display: flex; flex-direction: column;">
                            <div class="d-flex">
                                <div style="height: 30px; width: 30px; padding-left: 4px">
                                    <v-avatar  size="26" color="greyBase" v-if="message.type == 'received'">
                                        <v-img v-if="message.user?.avatar" :src="message.user.avatar"></v-img>
                                        <span v-else>{{ message.user.firstname.charAt(0) + message.user.surname.charAt(0) }}</span>
                                    </v-avatar>
                                </div>
                                <div class="receivedTail" v-if="message.type == 'received'"></div>
                                <v-card class="chatReceived" style="display: block;" v-if="message.type == 'received'">
                                    <b>{{ message.user.firstname }} {{ message.user.surname }}</b> <span v-if="message.organisation" style="opacity: 0.7; font-size: 10px;">({{message.organisation.alias ?? message.organisation.name.split(' ')[0] ?? ''}})</span><br>
                                    {{message.comment}}
                                    <span style="position: absolute; right: 5px; bottom: 0px; font-size: 0.58rem; opacity: 0.6">{{ $Format.formatDate(message.createdAt).time }}</span>
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="12" :sm="message.type == 'sent' ? '9' : '3'" :md="message.type == 'sent' ? '9' : '3'" :lg="message.type == 'sent' ? '9' : '3'" class=" py-1 d-flex justify-end align-end" style="contain:content;" :style="message.type == 'received' ? {'height' : 'auto'} : {'height' : 'min-content'}">
                            <div class="sentTail" v-if="message.type == 'sent'"></div>
                            <v-card class="chatSent" v-if="message.type == 'sent'">
                                {{message.comment}}
                                <span style="position: absolute; right: 5px; bottom: 0px; font-size: 0.58rem; opacity: 0.6">{{ $Format.formatDate(message.createdAt).time }}</span>
                            </v-card>
                            <div style="height: 100%; width: 100%;" class="d-flex align-center justify-end pr-2" v-if="message.context && message.bookingContainerId && message.type == 'received'">
                                <span  style="opacity: 0.4; font-size: 10px;">{{message.context ? message.context == 'container' ? containers.find(x => x.id == message.bookingContainerId).ctoNo : '' : ''}}</span>
                            </div>
                        </v-col>
                    </v-row>
                </div>
                <div class="chatTextArea py-1 pl-2">
                    <v-textarea
                        class="textBox"
                        v-model="newComment"
                        @keydown.enter="sendMessage"
                        solo
                        auto-grow
                        rows="1"
                        hide-details  
                        placeholder="Type a message"
                    />
                    <v-btn @click="sendMessage" small plain icon><v-icon class="pl-1">send</v-icon></v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['orderId', 'order', 'containers'],
    data: () => ({
        conversations: [],
        primaryConversations: [],
        selectedConversation: null,
        newComment: '',
        chatUpdateKey: 0,
        messages: [],
        alreadyConnected: false,
    }),
    watch: {
        containers: {
            handler(newVal, oldVal) {
                if(newVal) {
                    this.getConversationsByOrder();
                }
                
            },
            immediate: true
        },
        orderId: {
            handler(newVal, oldVal) {
                if(!this.alreadyConnected) {
                    console.log(this.$socket.readyState)
                    this.$socket.send(
                      JSON.stringify({
                        action: "join",
                        room: `trading_internal_${this.$store.state.currentOrg.id}_${this.orderId}`,
                        user: {
                          id: this.$store.state.user.id,
                          name: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
                          avatar: this.$store.state.user.avatar,
                          company: this.$store.state.currentOrg.name,
                        },
                      })
                    );
                    this.$socket.send(
                      JSON.stringify({
                        action: "join",
                        room: `trading_external_${this.orderId}`,
                        user: {
                          id: this.$store.state.user.id,
                          name: this.$store.state.user.firstname + " " + this.$store.state.user.surname,
                          avatar: this.$store.state.user.avatar,
                          company: this.$store.state.currentOrg.name,
                        },
                      })
                    );
                    this.alreadyConnected = true;
                }
            },
            immediate: true
        }
    },
    // async created() {
    //     this.getConversationsByOrder();
    // },
    computed: {
        structureMessages(){
            let result = []
            if(this.selectedConversation) {
                if(this.selectedConversation.messages.length > 0) {
                    let currentUserId = this.$store.state.user.id;
                    let messages = JSON.parse(JSON.stringify(this.selectedConversation.messages));
                    for(let i = 0; i < messages.length; i++) {
                        if(messages[i].userId === currentUserId) {
                            messages[i].type = 'sent'
                        } else {
                            messages[i].type = 'received'
                        }
                    }
                    result = messages 
                } else {
                    result = [];
                }
            }
            return result
        },
    },
    mounted() {

        this.$socket.onmessage = (data) => {
        console.log(data)
          let message = JSON.parse(data.data);
          this.handleMessage(message);
        };
    },
    beforeUnmount() {
      delete this.$socket.onmessage
      this.$socket.send(
        JSON.stringify({
          action: "leave",
          room: `trading_internal_${this.$store.state.currentOrg.id}_${this.orderId}`,
        })
      );
      this.$socket.send(
        JSON.stringify({
          action: "leave",
          room: `trading_external_${this.orderId}`,
        })
      );
    },
    methods: {
        handleMessage(message) {
            console.log(message);
            if(message.type === 'message') {
                let messageData = message.data
                messageData.unread = true;
                if(messageData.bookingId == this.orderId) {
                    if(messageData.isInternal) {
                        let convo = this.primaryConversations.find(x => x.type == 'Internal');
                        if(convo) {
                            convo.messages.push(messageData);
                            convo.unreadTotal ? convo.unreadTotal++ : convo.unreadTotal = 1;
                            this.chatUpdateKey++
                            }
                        } else {
                            let convo = this.primaryConversations.find(x => x.type == 'External');
                            if(convo) {
                                convo.messages.push(messageData);
                                convo.unreadTotal ? convo.unreadTotal++ : convo.unreadTotal = 1;
                                this.chatUpdateKey++
                            }
                        }
                        this.totalUnreadChanged()
                }
            }
        },
        async sendMessage() {
            let message = {
                comment: this.newComment,
                createdAt: new Date(),
                userId: this.$store.state.user.id,
                user: this.$store.state.user,
                organisation: this.$store.state.currentOrg,
                bookingId: this.orderId,
                isInternal: this.selectedConversation.type ? this.selectedConversation.type == 'Internal' : false,
            }
            this.selectedConversation.messages.push(message);
            setTimeout(() => {
                this.newComment = '';
            }, 50);
            this.scrollToBottom();
            let result = await this.$API.LLChatsendMessage(message)
            if(result) {
                console.log(result)
                message.id = result.id;
                if(message.isInternal) {
                    this.$socket.send(
                        JSON.stringify({
                            action: "sendmessage_room",
                            room: `trading_internal_${this.$store.state.currentOrg.id}_${this.orderId}`,
                            message: {
                              type: "message",
                              room: `trading_internal_${this.$store.state.currentOrg.id}_${this.orderId}`,
                              data: message,
                            },
                        })
                    );
                } else {
                    this.$socket.send(
                        JSON.stringify({
                            action: "sendmessage_room",
                            room: `trading_external_${this.orderId}`,
                            message: {
                              type: "message",
                              room: `trading_external_${this.orderId}`,
                              data: message,
                            },
                        })
                    );
                }
            }
        },
        exitConversation() {
            for(let message of this.selectedConversation.messages) {
                if(message.unread) {
                    message.unread = false;
                }
            }
            if(this.selectedConversation.unreadTotal) {
                this.selectedConversation.unreadTotal = 0;
                this.totalUnreadChanged()
            }
            this.selectedConversation = null;
        },
        async selectConvo(convo) {
            this.selectedConversation = convo;
            this.$nextTick(() => {
                this.scrollToBottom();
            })
            let unread = {
                bookingContainerCommentIds: [],
                bookingCommentIds: []
            }
            for(let message of this.selectedConversation.messages) {
                if(message.unread) {
                    if(message.bookingContainerId) {
                        unread.bookingContainerCommentIds.push(message.id)
                    } else if(message.bookingId) {
                        unread.bookingCommentIds.push(message.id)
                    }
                }
            }
            if(unread.bookingContainerCommentIds.length > 0 || unread.bookingCommentIds.length > 0) {
                await this.$API.messageRead(unread)
            }
        },
        async getConversationsByOrder() {
            if(this.orderId && this.containers.length > 0) {
                let response = await this.$API.getConversationsByOrder({
                    params: {
                        orderId: this.orderId,
                        containerIds: this.containers.map(container => container.id)
                    }
                })
                if(response) {
                    let mainConvos = [{type: 'Internal', orderNo: this.order?.orderNo ?? null, messages: response.groupedConversations.filter(x => x.isInternal)}, {type: 'External', orderNo: this.order?.orderNo ?? null, messages: response.groupedConversations.filter(x => !x.isInternal)}];
                    mainConvos[0].unreadTotal = mainConvos[0]?.messages?.filter(x => x.unread)?.length ?? 0;
                    mainConvos[1].unreadTotal = mainConvos[1]?.messages?.filter(x => x.unread)?.length ?? 0;
                    this.primaryConversations = mainConvos;
                    this.totalUnreadChanged()
                }
            }
        },
        totalUnreadChanged() {
            let total = 0;
            for(let convo of this.primaryConversations) {
                total += convo.unreadTotal
            }
            this.$emit('totalUnreadChanged', total)
            return total
        },
        showDate(item, index) {
            let result = false
            if(index === 0) {
              result = true
            }else if(this.selectedConversation.messages[index - 1] && this.selectedConversation.messages[index - 1].createdAt && item.createdAt) {
              let previous = this.selectedConversation.messages[index - 1]
              let previousDate = this.$Format.formatDate(previous.createdAt).dayMonthYear
              let currentDate = this.$Format.formatDate(item.createdAt).dayMonthYear
              if(previousDate != currentDate) {
                result = true
              }
            }
            return result
        },
        scrollToBottom() {
            this.$nextTick(() => {
              let scroll = document.getElementById('chatList')
              if(scroll) {
                  scroll.scrollTop = scroll.scrollHeight;
                }
                else {
                    this.$nextTick(() => {
                        let scroll = document.getElementById('chatList')
                        if(scroll) {
                            scroll.scrollTop = scroll.scrollHeight;
                        }
                    })
                }
            });
        },
        displayDate(date) {
          let testDate = this.$Format.formatDate(date).dayMonthYear
          let today = this.$Format.formatDate(new Date()).dayMonthYear
          let yesterday = this.$Format.formatDate(new Date(new Date().setDate(new Date().getDate() - 1))).dayMonthYear
          let twoDays = this.$Format.formatDate(new Date(new Date().setDate(new Date().getDate() - 2))).dayMonthYear
          let threeDays = this.$Format.formatDate(new Date(new Date().setDate(new Date().getDate() - 3))).dayMonthYear
          let fourDays = this.$Format.formatDate(new Date(new Date().setDate(new Date().getDate() - 4))).dayMonthYear
          let fiveDays = this.$Format.formatDate(new Date(new Date().setDate(new Date().getDate() - 5))).dayMonthYear

          if(testDate == today) {
            return 'Today'
          } else if(testDate == yesterday) {
            return 'Yesterday'
          } else if(testDate == twoDays || testDate == threeDays || testDate == fourDays || testDate == fiveDays) {
            return this.$Format.formatDate(date).dayOfWeek
          } else {
            return this.$Format.formatDate(date).fullDate
          }
        },
    }
}
</script>

<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.chatParent {
    height: calc(100vh - 77px);
    display: flex;
    flex-direction: column;
}
.messages {
    min-height: 85%;
    height: 94%;
    max-height: 94%;
    overflow-y: auto;
    background: var(--v-greyRaised-lighten1) !important;
}
.chatTextArea {
    min-height: 6%;
    height: auto;
    max-height: 15%;
    display: flex;
    align-items: center;
}
.textBox {
    min-height: 5vh;
    max-height: 100%;
    border-radius: 8px;
    overflow-y: auto;
}
.receivedTail {
    z-index: 3;
    content: ' '; 
    position: absolute; 
    width: 0; 
    height: 0; 
    left: 31px; 
    right: auto; 
    top: 4px; 
    bottom: auto; 
    border: 7px solid; 
    border-color: var(--v-greyBase-base) transparent transparent transparent;
}
.chatReceived {
    position: relative;
    background-color: var(--v-greyBase-base) !important;
    padding: 0 36px 0px 12px;
    border-radius: 8px;
    margin: 0 0 0 8px;
    max-width: 100%;
    width: fit-content;
    height: auto;
    min-height: 25px;
}
.sentTail {
    z-index: 3;
    content: ' '; 
    position: absolute; 
    width: 0; 
    height: 0; 
    right: 1px; 
    top: 4px; 
    bottom: auto; 
    border: 7px solid; 
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}
.chatSent {
    position: relative;
    background-color: rgb(255, 248, 239) !important;
    border-radius: 8px;
    padding: 0 36px 0 12px;
    color:#000;
    max-width: 40vw;
    width: fit-content;
    height: auto;
    min-height: 25px;
    margin: 0 8px 0 0;
}
.v-list-item {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.toolbars ::v-deep .v-toolbar__content {
  padding: 0;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;

}
.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}

</style>