<template>
    <div>
        <v-card flat>
            <!-- <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn text @click="$emit('close')">X</v-btn>
            </v-toolbar> -->

            <v-row justify="end" align="center">
                <v-spacer></v-spacer>
                <v-row justify="center" class="my-2" align="center">
                    <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip color="blue" class="mx-1" outlined v-bind="attrs" v-on="on" small><v-icon small
                                    left>date_range</v-icon>{{ dateText }}
                                <v-icon small right>expand_more</v-icon></v-chip>
                        </template>
                        <v-date-picker v-model="params.dates" range color="primary" show-week scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary"> Cancel </v-btn>
                            <v-btn text color="primary" @click="setDateFilter">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                    <v-chip small class="mx-1" @click="setDateType('week')"
                        :color="selectedDateType == 'week' ? 'teal' : ''">
                        <v-icon small left v-if="selectedDateType == 'week'">check</v-icon>
                        Next 7 Days</v-chip>
                    <v-chip small class="mx-1" @click="setDateType('2_week')"
                        :color="selectedDateType == '2_week' ? 'teal' : ''">
                        <v-icon small left v-if="selectedDateType == '2_week'">check</v-icon>
                        Next 2 weeks</v-chip>
                    <v-chip small class="mx-1" @click="setDateType('4_week')"
                        :color="selectedDateType == '4_week' ? 'teal' : ''">
                        <v-icon small left v-if="selectedDateType == '4_week'">check</v-icon>
                        Next 4 weeks</v-chip>
                    <v-chip small class="mx-1" @click="setDateType('6_week')"
                        :color="selectedDateType == '6_week' ? 'teal' : ''">
                        <v-icon small left v-if="selectedDateType == '6_week'">check</v-icon>
                        Next 6 weeks</v-chip>
                    <v-switch class="mx-1" label="Direct Only" v-model="params.direct" color="blue"></v-switch>
                    <div v-if="tempPod">
                        <v-chip @click="selectPod('temporary')" small outlined lass="mx-1" close
                            @click:close="removeTempPod">
                            <v-avatar size="24" left>
                                <v-img contain v-if="tempPod" :src="`https://cdn.loglive.io/flags/4x3/${tempPod
                                    .substring(0, 2)
                                    .toLowerCase()}.svg`"></v-img>
                            </v-avatar>
                            {{ tempPod }}
                        </v-chip>
                    </div>
                    <div v-else>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="selectPod('temporary')" color="secondary" small
                                    class="mx-1">
                                    <v-icon>travel_explore</v-icon></v-btn>
                            </template>
                            <span style="font-size: 12px">Transhipment Port Search</span>

                        </v-tooltip>
                    </div>

                    <div v-if="!this.export">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" text icon @click="addManualSchedule" class="ml-1"> <v-icon
                                        color="primary">add_circle_outline</v-icon> </v-btn>
                            </template>
                            <span style="font-size: 12px">Add Vessel & Schedule</span>
                        </v-tooltip>
                    </div>
                    <div v-else-if="$store.state.user.tagId === 1 || $store.state.user.tagId === 2">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" text icon @click="addManualSchedule" class="ml-1"> <v-icon
                                        color="primary">add_circle_outline</v-icon> </v-btn>
                            </template>
                            <span style="font-size: 12px">Add Vessel & Schedule</span>
                        </v-tooltip>
                    </div>
                    <v-spacer></v-spacer>

                    <!-- <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" text icon @click="getSchedules" class="mr-2"> <v-icon
                                    color="primary">refresh</v-icon> </v-btn>
                        </template>
                        <span style="font-size: 12px">Reload Schedules</span>
                    </v-tooltip> -->
                </v-row>
                <v-col cols="12" sm="4">
                    <v-text-field hide-details outlined dense placeholder="Search" v-model="search" rounded clearable
                        prepend-inner-icon="search"></v-text-field>
                </v-col>
            </v-row>
            <v-card-text class="mt-0 pt-0">
                <v-data-table :headers="headers" :items="filterSchedules" :loading="loading" fixed-header
                    :group-by="'position'" hide-default-footer disable-pagination height="65vh" :expanded="expanded"
                    :search="search">

                    <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                        <th :colspan="headers.length">
                            <v-icon @click="toggle" small class="mr-1">{{ isOpen ? 'remove' : 'add' }}
                            </v-icon>
                            <v-chip small class="mx-1">{{ items.length }}</v-chip>
                            <span>{{ items[0].vesselDescription }} {{ items[0].voyage }}</span>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" class="ml-2" icon text @click="addSchedule(items[0])">
                                        <v-icon color="primary">add_circle_outline</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Add Schedule</span>
                            </v-tooltip>
                        </th>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <div v-if="tempPod">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" style="border: 1px dotted grey" text icon
                                        @click="createTranshipment(item)">
                                        <v-icon small color="green">add_to_photos</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 12px">Add Transhipment Routing</span>
                            </v-tooltip>
                        </div>
                        <div v-else class="d-flex">
                            <v-btn :class="findSailingScheduleId(sailingScheduleId,item.id)"
                                style="border: 1px dotted grey;" text icon @click="selectItem(item)">
                                <v-icon small>check</v-icon>
                            </v-btn>

                            <v-btn style="border: 1px dotted grey" v-if="item.canDelete" @click="deleteSchedule(item)"
                                text icon>
                                <v-icon small color="red">delete</v-icon>
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:[`item.carrier`]="{ item }">
                        <v-chip v-if="item.shippingLine" small class="primaryText--text" outlined
                            style="border: none; font-size: 11px">
                            <v-avatar size="24"
                                :color="item.shippingLine && item.shippingLine.logo ? 'white' : 'secondary'" left>
                                <v-img v-if="item.shippingLine.logo" :src="item.shippingLine.logo" contain></v-img>
                                <div v-else>
                                    <h3 v-if="item.shippingLine.alias" style="color: white">{{
                                        item.shippingLine.alias.charAt(0) }}</h3>
                                    <h3 v-else style="color: white">{{ item.shippingLine.name.charAt(0) }}</h3>
                                </div>

                            </v-avatar> {{ item.shippingLine.friendlyName ?? item.shippingLine.name }}
                        </v-chip>
                        <v-chip v-else small outlined style="border: none; font-size: 11px">{{ item.carrier }}</v-chip>
                    </template>

                    <template v-slot:[`item.stacksOpen`]="{ item }">
                        <div v-if="item.stackDate" class="py-1">
                            <v-tooltip top v-if="item.stackDate.reeferStackStart">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.reeferStackStartConfirmed ? 'success' : 'orange'">R</v-avatar>
                                        {{ formatDateTime(item.stackDate.reeferStackStart) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Open: {{
                                    formatDateTime(item.stackDate.reeferStackStart) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">R</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Open: NONE</span>
                            </v-tooltip>
                            <br v-if="!item.stackDate.reeferStackStart">

                            <v-tooltip top v-if="item.stackDate.dryStackStart">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.dryStackStartConfirmed ? 'success' : 'orange'">D</v-avatar>
                                        {{ formatDateTime(item.stackDate.dryStackStart) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Open: {{
                                    formatDateTime(item.stackDate.dryStackStart) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">D</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Open: NONE</span>
                            </v-tooltip>
                            <br v-if="!item.stackDate.dryStackStart">

                            <v-tooltip top v-if="item.stackDate.fisStart">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.fisStartConfirmed ? 'success' : 'orange'">F</v-avatar>
                                        {{ formatDateTime(item.stackDate.fisStart) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Open: {{
                                    formatDateTime(item.stackDate.fisStart) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">F</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Open: NONE</span>
                            </v-tooltip>

                        </div>
                        <div v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">R</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Open: NONE</span>
                            </v-tooltip>
                            <br>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">D</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Open: NONE</span>
                            </v-tooltip>
                            <br>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">F</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Open: NONE</span>
                            </v-tooltip>
                        </div>

                    </template>
                    <template v-slot:[`item.stacksClose`]="{ item }">
                        <div v-if="item.stackDate" class="py-1">
                            <v-tooltip top v-if="item.stackDate.reeferStackFinish">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.reeferStackFinishConfirmed ? 'success' : 'orange'">R</v-avatar>
                                        {{ formatDateTime(item.stackDate.reeferStackFinish) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Close: {{
                                    formatDateTime(item.stackDate.reeferStackFinish) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">R</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Close: NONE</span>
                            </v-tooltip>
                            <br v-if="!item.stackDate.reeferStackFinish">

                            <v-tooltip top v-if="item.stackDate.dryStackFinish">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.dryStackFinishConfirmed ? 'success' : 'orange'">D</v-avatar>
                                        {{ formatDateTime(item.stackDate.dryStackFinish) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Close: {{
                                    formatDateTime(item.stackDate.dryStackFinish) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">D</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Close: NONE</span>
                            </v-tooltip>
                            <br v-if="!item.stackDate.dryStackFinish">

                            <v-tooltip top v-if="item.stackDate.fisFinish">
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left
                                            :color="item.stackDate.fisFinishConfirmed ? 'success' : 'orange'">F</v-avatar>
                                        {{ formatDateTime(item.stackDate.fisFinish) }}
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Close: {{
                                    formatDateTime(item.stackDate.fisFinish) }}</span>
                            </v-tooltip>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">F</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Close: NONE</span>
                            </v-tooltip>

                        </div>
                        <div v-else>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">R</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Reefer Stacks Close: NONE</span>
                            </v-tooltip>
                            <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">D</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">Dry Stacks Close: NONE</span>
                            </v-tooltip>
                            <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip x-small outlined style="border: none" v-on="on">
                                        <v-avatar size="22" left :color="'grey'">F</v-avatar>
                                        -
                                    </v-chip>
                                </template>
                                <span style="font-size: 12px">FIS Stacks Close: NONE</span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:[`item.legs`]="{ item }">
                        <v-btn small outlined icon :color="item.legs == 1 ? 'success' : 'blue'"
                            @click="expandRow(item)">
                            {{ item.legs }}
                        </v-btn>
                    </template>
                    <template v-slot:[`item.portOfLoadValue`]="{ item }">
                        <v-chip outlined style="border: none" small>
                            <v-avatar size="20" left>
                                <v-img contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                            </v-avatar> {{ item.portOfLoadValue }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.finalDestinationValue`]="{ item }">
                        <v-chip outlined style="border: none" small>
                            <v-avatar size="20" left>
                                <v-img contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                            </v-avatar> {{ item.finalDestinationValue }} <span v-if="item.via" class="ml-1"> via
                                <v-avatar size="20" class="mx-1"><v-img contain
                                        :src="`https://cdn.loglive.io/flags/4x3/${item.via.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                </v-avatar>{{ item.via }}</span>
                        </v-chip>
                    </template>

                    <template v-slot:[`item.etd`]="{ item }">
                        <!-- <v-tooltip top v-if="item.systemETD">
                            <template v-slot:activator="{ on }">
                                <v-chip small outlined style="border: none" v-on="on">
                                    <v-icon color="grey" small left>settings_suggest</v-icon>
                                    {{ formatDateTime(item.systemETD) }}
                                </v-chip>
                            </template>
                            <span style="font-size: 12px">Source: RMS</span>
                        </v-tooltip> -->

                        <v-chip small outlined style="border: none">
                            <v-icon color="grey" small left>schedule</v-icon>
                            {{ formatDateTime(item.etd) }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.eta`]="{ item }">
                        <v-chip small outlined style="border: none">
                            <v-icon color="grey" small left>schedule</v-icon>
                            {{ formatDateTime(item.eta) }}
                        </v-chip>
                    </template>

                    <template v-slot:expanded-item="{ headers, item }">
                        <td colspan="2"></td>
                        <td class="px-1" :colspan="headers.length - 2">
                            <!-- <v-expand-transition> -->
                            <v-card class="my-1" flat :loading="loadingScheduleLegs">
                                <v-card-text class="my-0 py-0">
                                    <table style="width: 100%">
                                        <thead style="background: transparent !important; border: none">
                                            <tr>
                                                <th style="background: transparent !important; border-bottom: none">
                                                </th>
                                                <th style="background: transparent !important; border-bottom: none">
                                                    Leg</th>
                                                <th style="background: transparent !important">Port of
                                                    Load</th>
                                                <th style="background: transparent !important">Port of
                                                    Discharge</th>
                                                <th style="background: transparent !important">ETD</th>
                                                <th style="background: transparent !important">ETA</th>
                                                <th style="background: transparent !important">Vessel
                                                </th>
                                                <th style="background: transparent !important">Voyage
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="padding-top: 10px" class="mt-1">
                                            <tr v-for="leg in item.sailingScheduleLegs" :key="leg.id">
                                                <td class="text-center">
                                                    <v-icon>subdirectory_arrow_right</v-icon>
                                                </td>
                                                <td class="text-center">
                                                    <v-chip small>{{ leg.leg }}</v-chip>
                                                </td>
                                                <td class="text-center">
                                                    <v-chip v-if="leg.portOfLoadValue" outlined small
                                                        style="border: none">
                                                        <v-avatar size="24" left>
                                                            <v-img contain v-if="leg.portOfLoadValue"
                                                                :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                        </v-avatar>
                                                        {{ leg.portOfLoadValue }}
                                                    </v-chip>
                                                </td>
                                                <td class="text-center">
                                                    <v-chip v-if="leg.portOfDischargeValue" outlined small
                                                        style="border: none">
                                                        <v-avatar size="24" left>
                                                            <v-img contain v-if="leg.portOfDischargeValue"
                                                                :src="`https://cdn.loglive.io/flags/4x3/${leg.portOfDischargeValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                        </v-avatar>
                                                        {{ leg.portOfDischargeValue }}
                                                    </v-chip>
                                                </td>
                                                <td class="text-center">
                                                    {{ formatDateTime(leg.etd) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ formatDateTime(leg.eta) }}
                                                </td>
                                                <td class="text-center">
                                                    {{ leg.vesselDescription }}
                                                </td>
                                                <td class="text-center">
                                                    {{ leg.voyage }}
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>
                                </v-card-text>
                            </v-card>
                        </td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <!-- Manual Vessel & Schedule Add -->
        <v-dialog v-model="manualVesselDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card :loading="savingSchedule"
                style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; " outlined>
                <v-card-title>
                    <v-icon class="mr-2" color="teal">sailing</v-icon>
                    Add Vessel Schedule
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="validateVesselSave" @click="saveVesselSchedule"> <v-icon
                            color="secondary">save</v-icon>
                    </v-btn>
                    <v-btn text @click="manualVesselDialog = false">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container v-if="manualVessel">
                        <v-list dense class="text-center">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px"> <v-icon class="mr-2"
                                    color="grey">settings_suggest</v-icon>New Vessel Details</v-subheader>
                            <v-divider></v-divider>
                            <v-list dense class="text-left">
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-icon
                                            :color="manualVessel.vessel ? 'green' : 'secondary'">directions_boat</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content v-if="manualVessel.vessel">
                                        <v-list-item-title>
                                            {{ manualVessel.vessel.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <u style="cursor: pointer" @click="voyageNumberModal = true"
                                                v-if="manualVessel.voyage">Voyage No:
                                                {{ manualVessel.voyage }}</u>
                                            <u style="cursor: pointer" @click="voyageNumberModal = true" v-else>No
                                                Voyage
                                                No</u>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-content v-else>
                                        <v-list-item-content>
                                            <v-list-item-title>Add Vessel</v-list-item-title>
                                            <v-list-item-subtitle><i>(Required)</i></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-row justify="center">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" color="blue" icon @click="vesselModal = true">
                                                        <v-icon>manage_search</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span style="font-size: 12px">Search Vessels</span>
                                            </v-tooltip>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>


                                <v-list-item @click="shippingLineModal = true">
                                    <v-list-item-action v-if="!manualVessel.shippingLine">
                                        <v-icon color="secondary">directions_boat</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-avatar v-else>
                                        <v-avatar color="white" size="36" v-if="manualVessel.shippingLine">
                                            <v-img v-if="manualVessel.shippingLine && manualVessel.shippingLine.logo
                                                " :src="manualVessel.shippingLine.logo" contain></v-img>
                                        </v-avatar>
                                    </v-list-item-avatar>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-title v-if="manualVessel.shippingLine">{{
                                            manualVessel.shippingLine.name
                                            }}</v-list-item-title>
                                        <v-list-item-title v-else>-</v-list-item-title>
                                        <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                                    </v-list-item-content>

                                </v-list-item>
                                <v-list-item style="height: 35px">
                                    <v-list-item-action>
                                        <v-icon color="green">anchor</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip v-if="manualVessel.portOfLoadValue" pill small outlined>
                                                <v-avatar size="24" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${manualVessel.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ manualVessel.portOfLoadValue }}
                                            </v-chip>
                                            <v-icon class="mx-3" color="grey" small>east</v-icon>
                                            <v-chip v-if="manualVessel.finalDestinationValue" pill small outlined>
                                                <v-avatar size="24" left>
                                                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${manualVessel.finalDestinationValue
                                                        .substring(0, 2)
                                                        .toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ manualVessel.finalDestinationValue }}
                                            </v-chip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <div>
                                    <v-menu :close-on-content-click="true" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on" class="text-left">
                                                <v-list-item-action>
                                                    <v-icon
                                                        :color="manualVessel.etd ? 'success' : 'secondary'">today</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-if="manualVessel.etd">
                                                        {{ formatDate(manualVessel.etd) }}
                                                    </v-list-item-title>
                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Estimated Departure
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <v-date-picker v-model="manualVessel.etd" no-title scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                                <div>
                                    <v-menu :close-on-content-click="true" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on" class="text-left">
                                                <v-list-item-action>
                                                    <v-icon
                                                        :color="manualVessel.eta ? 'success' : 'secondary'">today</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-if="manualVessel.eta">
                                                        {{ formatDate(manualVessel.eta) }}
                                                    </v-list-item-title>
                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Estimated Arrival
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <v-date-picker v-model="manualVessel.eta" no-title scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                            </v-list>
                        </v-list>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Manual Schedule Add -->
        <v-dialog v-model="manualScheduleDialog" max-width="500px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card :loading="savingSchedule"
                style=" border-radius: 20px; background-color: var(--v-toolbar-lighten1) !important; " outlined>
                <v-card-title>
                    <v-icon class="mr-2" color="teal">event</v-icon>
                    Add Transhipment Schedule
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="validateScheduleSave" @click="saveSchedule"> <v-icon
                            color="secondary">save</v-icon>
                    </v-btn>
                    <v-btn text @click="manualScheduleDialog = false">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container v-if="newSchedule">
                        <v-list dense class="text-center">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px">
                                <v-icon class="mr-2" color="grey">settings</v-icon> Vessel Details
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-list dense class="text-left">
                                <v-list-item style="height: 35px">
                                    <v-list-item-action>
                                        <v-icon color="grey">directions_boat</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ newSchedule.vesselDescription }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <u style="cursor: pointer">Voyage No: {{ newSchedule.voyage }}</u>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item style="height: 35px">
                                    <v-list-item-action>
                                        <v-icon color="grey">anchor</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-chip v-if="newSchedule.portOfLoadValue" pill small outlined>
                                                <v-avatar size="24" left>
                                                    <v-img contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${newSchedule.portOfLoadValue.substring(0, 2).toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ newSchedule.portOfLoadValue }}
                                            </v-chip>
                                            <v-icon class="mx-3" color="grey" small>east</v-icon>
                                            <v-chip v-if="newSchedule.finalDestinationValue" pill small outlined>
                                                <v-avatar size="24" left>
                                                    <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${newSchedule.finalDestinationValue
                                                        .substring(0, 2)
                                                        .toLowerCase()}.svg`"></v-img>
                                                </v-avatar>
                                                {{ newSchedule.finalDestinationValue }}
                                            </v-chip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-icon color="grey">today</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-if="newSchedule.etd">
                                            {{ formatDate(newSchedule.etd) }}
                                        </v-list-item-title>
                                        <v-list-item-title v-else> - </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Estimated Departure
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <div>
                                    <v-menu :close-on-content-click="true" transition="scale-transition" offset-y
                                        min-width="auto">
                                        <template v-slot:activator="{ on }">
                                            <v-list-item v-on="on" class="text-left">
                                                <v-list-item-action>
                                                    <v-icon
                                                        :color="newSchedule.eta ? 'success' : 'secondary'">today</v-icon>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title v-if="newSchedule.eta">
                                                        {{ formatDate(newSchedule.eta) }}
                                                    </v-list-item-title>
                                                    <v-list-item-title v-else> - </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        Estimated Arrival
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                        <v-date-picker v-model="newSchedule.eta" no-title scrollable>
                                        </v-date-picker>
                                    </v-menu>
                                </div>
                            </v-list>
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px"> <v-icon class="mr-2"
                                    color="grey">settings_suggest</v-icon>New Details</v-subheader>
                            <v-divider></v-divider>
                            <v-list dense>
                                <v-list-item @click="shippingLineModal = true">

                                    <v-list-item-action v-if="!newSchedule.shippingLine">
                                        <v-icon color="secondary">directions_boat</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-avatar v-else>
                                        <v-avatar color="white" size="36" v-if="newSchedule.shippingLine">
                                            <v-img v-if="newSchedule.shippingLine && newSchedule.shippingLine.logo
                                                " :src="newSchedule.shippingLine.logo" contain></v-img>
                                        </v-avatar>
                                    </v-list-item-avatar>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-title v-if="newSchedule.shippingLine">{{
                                            newSchedule.shippingLine.name
                                            }}</v-list-item-title>
                                        <v-list-item-title v-else>-</v-list-item-title>
                                        <v-list-item-subtitle>Shipping Line</v-list-item-subtitle>
                                    </v-list-item-content>

                                </v-list-item>

                                <v-list-item @click="selectPod('permanent')">
                                    <v-list-item-action>
                                        <v-icon :color="!newSchedule.via ? 'secondary' : 'success'">anchor</v-icon>
                                    </v-list-item-action>

                                    <v-list-item-content class="text-left">
                                        <v-list-item-title v-if="newSchedule.via">{{ newSchedule.via
                                            }}</v-list-item-title>
                                        <v-list-item-title v-else>-</v-list-item-title>
                                        <v-list-item-subtitle>Routing Port</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-chip v-if="newSchedule.via" pill small outlined>
                                            <v-avatar size="24" left>
                                                <v-img contain v-if="newSchedule.via" :src="`https://cdn.loglive.io/flags/4x3/${newSchedule.via
                                                    .substring(0, 2)
                                                    .toLowerCase()}.svg`"></v-img>
                                            </v-avatar>
                                            {{ newSchedule.via }}
                                        </v-chip>
                                    </v-list-item-action>
                                </v-list-item>

                            </v-list>
                        </v-list>

                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- SL Select Modal -->
        <v-dialog v-model="shippingLineModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>Set Shipping Line</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="shippingLineModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field dense outlined rounded prepend-inner-icon="search" autofocus placeholder="Search"
                        clearable v-model="searchShippingLine"></v-text-field>
                    <v-list dense style="max-height: 50vh; overflow-y: auto">
                        <v-list-item v-for="carrier in filterShippingLines" :key="carrier.id"
                            @click="setShippingLine(carrier)">
                            <v-list-item-action>
                                <v-avatar v-if="carrier.logo" color="white">
                                    <v-img :src="carrier.logo" contain></v-img>
                                </v-avatar>
                                <v-avatar v-else color="secondary">
                                    <h3>{{ carrier.name.charAt(0) }}</h3>
                                </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ carrier.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    carrier.friendlyName
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- POD Modal Select -->
        <v-dialog v-model="podModal" width="400px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title v-if="podType === 'temporary'">
                        <v-icon class="mr-2" color="secondary">anchor</v-icon> Select Transhipment
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <v-icon class="mr-2" color="secondary">anchor</v-icon> Manage POD
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="podModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field class="my-0 py-0" autofocus prepend-inner-icon="search" dense placeholder="Search"
                        outlined clearable v-model="portSearch" hide-details></v-text-field>
                    <div>
                        <v-progress-linear v-if="loadingDestinationPorts" color="primary"
                            indeterminate></v-progress-linear>
                        <v-expand-transition>
                            <v-card flat class="my-0 py-0">
                                <v-card-text class="my-0 py-0">
                                    <v-virtual-scroll v-if="portResults.length > 0" class="my-0 py-0" :bench="0"
                                        :items="portResults" height="250" item-height="50">
                                        <template v-slot:default="{ item }">
                                            <v-list-item :key="item.id" @click="setPort(item)">
                                                <v-list-item-avatar>
                                                    <v-avatar size="24" class="mr-1" left>
                                                        <v-img contain v-if="item.locode" :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                                            .substring(0, 2)
                                                            .toLowerCase()}.svg`"></v-img>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title style="font-size: 14px">
                                                        {{ item.name }}
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle style="font-size: 12px; color: grey">
                                                        {{ item.locode }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-virtual-scroll>
                                    <v-list-item v-else>
                                        <v-list-item-content class="text-center">
                                            <span style="color: grey; font-size: 12px">No results found</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card-text>
                            </v-card>
                        </v-expand-transition>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="etaDialog" width="400px">
            <v-card>
                <v-card-title>
                    Manually Set ETA
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="!selectedEtaDate" @click="setETDData(selectedEtaDate)"> <v-icon
                            color="secondary">save</v-icon></v-btn>
                    <v-btn text @click="etaDialog = false">X</v-btn>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" class="mt-2">
                        <v-col cols="12" sm="8" class="my-0 py-0">
                            <v-menu :close-on-content-click="true" transition="scale-transition" offset-y
                                min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on">
                                        <v-list-item-action>
                                            <v-icon color="grey">today</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-if="selectedEtaDate">
                                                {{ formatDate(selectedEtaDate) }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else>
                                                -
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                ETA Date
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-date-picker v-model="selectedEtaDate" no-title scrollable>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <!-- <v-col cols="12" sm="6" class="my-0 py-0" v-if="!hideDate">
                        <v-switch v-model="item.isActual" label="Actual Time" color="blue" dense></v-switch>
                    </v-col> -->

                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Vessel Search Modal -->
        <v-dialog v-model="vesselModal" width="400px" :fullscreen="$vuetify.breakpoint.mobile" persistent>
            <v-card :loading="vesselLoading">
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Search Vessel </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="vesselModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field outlined dense v-model="searchVessel" clearable placeholder="Search"
                        prepend-inner-icon="search"></v-text-field>
                    <v-list dense style="max-height: 50vh; overflow-y: auto">
                        <v-list-item v-for="vessel in vessels" :key="vessel.id" @click="setVessel(vessel)">
                            <v-list-item-avatar>
                                <v-img contain v-if="vessel.countryCode"
                                    :src="`https://cdn.loglive.io/flags/4x3/${vessel.countryCode.toLowerCase()}.svg`"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ vessel.name }}</v-list-item-title>
                                <v-list-item-subtitle v-if="vessel.imoNumber">IMO: {{ vessel.imoNumber
                                    }}</v-list-item-subtitle>
                                <v-list-item-subtitle v-if="vessel.mmsiNumber">MMSI: {{ vessel.mmsiNumber
                                    }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="vessels.length == 0">
                            <v-list-item-content class="text-center">
                                <span style="color: grey; font-size: 12px">No vessels found</span>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Manual Voyage Modal -->
        <v-dialog v-model="voyageNumberModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat dense color="transparent">
                    <v-spacer></v-spacer>
                    <v-btn text @click="voyageNumberModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field label="Voyage No" v-model="manualVessel.voyage" outlined dense
                        clearable></v-text-field>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="manualTranshipmentEtaModal" persistent width="400px"
            :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat dense color="transparent">
                    <v-card-title> Select FD ETA </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn text> <v-icon color="primary" @click="saveTranshipment">save</v-icon></v-btn>
                    <v-btn text @click="manualTranshipmentItem = {}, manualTranshipmentEtaModal = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list dense>
                        <div>
                            <v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on }">
                                    <v-list-item v-on="on" class="text-left">
                                        <v-list-item-action>
                                            <v-icon :color="manualTranshipmentItem.fdEta ? 'success' : 'secondary'">today</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-if="manualTranshipmentItem.fdEta">
                                                {{ formatDate(manualTranshipmentItem.fdEta) }}
                                            </v-list-item-title>
                                            <v-list-item-title v-else> - </v-list-item-title>
                                            <v-list-item-subtitle>
                                                Estimated Arrival
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                                <v-date-picker v-model="manualTranshipmentItem.fdEta" no-title scrollable>
                                </v-date-picker>
                            </v-menu>
                        </div>
                    </v-list>
                    <!-- <v-date-picker v-model="manualTranshipmentItem.fdEta" no-title scrollable> </v-date-picker> -->
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<script>
import dateFormat from "dateformat";
export default {
    props: ['export', 'setETD', 'portOfLoadValue', 'finalDestinationValue', 'shippingLines','sailingScheduleId'],
    data: () => ({
        dateMenu: false,
        tempPod: null,
        podType: '',
        vesselModal: false,
        vessels: [],
        vesselQuery: undefined,
        vesselTimeout: null,
        vesselLoading: false,
        searchVessel: null,
        voyageNumberModal: false,
        manualTranshipmentEtaModal: false,
        manualTranshipmentItem: {},
        manualVesselDialog: false,
        manualVessel: {
            vesselDescription: null,
            voyage: null,
            mainCarriageConveyanceNumber: null,
        },
        portResults: [],
        portSearch: null,
        portSearchQuery: undefined,
        portSearchTimeout: null,
        loadingDestinationPorts: false,
        manualScheduleDialog: false,
        newSchedule: null,
        savingSchedule: false,
        shippingLineModal: false,
        searchShippingLine: null,
        podModal: false,
        expanded: [],
        headers: [
            {
                text: '',
                value: 'action',
                align: 'center',
                sortable: false
            },
            {
                text: 'Vessel',
                value: 'vesselDescription',
                align: 'left',
            },
            {
                text: 'Voyage',
                value: 'voyage',
                align: 'left',
            },
            {
                text: 'Carrier',
                value: 'carrier',
                align: 'left',
            },
            {
                text: 'Service',
                value: 'serviceCode',
                align: 'left',
            },
            {
                text: 'Source',
                value: 'source',
                align: 'left',
            },
            {
                text: 'Stacks Open',
                value: 'stacksOpen',
                align: 'left',
                width: '100px',
                sortable: false
            },
            {
                text: 'Stacks Close',
                value: 'stacksClose',
                align: 'left',
                width: '100px',
                sortable: false
            },
            {
                text: 'Legs',
                value: 'legs',
                align: 'center',
            },
            {
                text: 'POL',
                value: 'portOfLoadValue',
                align: 'center',
            },
            {
                text: 'ETD',
                value: 'etd',
                align: 'center',
            },
            {
                text: 'Destination',
                value: 'finalDestinationValue',
                align: 'center',
            },
            {
                text: 'ETA',
                value: 'eta',
                align: 'center',
            },
            {
                text: 'Transit',
                value: 'transit',
                align: 'center',
            }
        ],
        loading: false,
        loadingScheduleLegs: false,
        params: {
            dates: [],
            direct: false
        },
        schedules: [],
        scheduleSearchQuery: null,
        scheduleSearchTimeout: null,
        search: null,
        searchKey: 1000,
        selectedDateType: '2_week',
        selectedEtaDate: null,
        etaDialog: false,
        selectedSailingScheduleData: null,
    }),
    computed: {
        dateText() {
            if (this.params.dates.length < 2) {
                return "Select Dates";
            } else {
                return `${this.params.dates[0]} - ${this.params.dates[1]}`;
            }
        },
        validateScheduleSave() {
            let result = true
            if (this.manualScheduleDialog) {
                if (this.newSchedule.via && this.newSchedule.via !== '' && this.newSchedule.eta && this.newSchedule.eta !== '' && this.newSchedule.shippingLineId) {
                    result = false
                }
            }
            return result
        },
        validateVesselSave() {
            let result = true
            if (this.manualVesselDialog) {
                if (this.manualVessel.eta && this.manualVessel.eta !== '' && this.manualVessel.etd && this.manualVessel.etd !== '' && this.manualVessel.shippingLineId && this.manualVessel.vesselId) {
                    result = false
                }
            }
            return result
        },
        filterSchedules() {
            let result = this.schedules
            return result
        },
        filterShippingLines() {
            let result = this.shippingLines;
            if (this.searchShippingLine) {
                result = result.filter(
                    (x) =>
                        (x.name &&
                            x.name
                                .toLowerCase()
                                .includes(this.searchShippingLine.toLowerCase())) ||
                        (x.friendlyName &&
                            x.friendlyName
                                .toLowerCase()
                                .includes(this.searchShippingLine.toLowerCase()))
                );
            }
            return result;
        },
    },
    watch: {
        export() {
            this.getSchedules()
        },
        portOfLoadValue() {
            this.getSchedules()
        },
        finalDestinationValue() {
            this.getSchedules()
        },
        search() {
            this.searchKey++
        },
        params: {
            handler() {
                this.getSchedules()
            },
            deep: true
        },
        async portSearch(val) {

            if (this.portSearchQuery) {
                this.portSearchQuery.abort();
            }
            this.loadingDestinationPorts = true;
            let params = {
                search: val,
                portType: "port",
            };
            this.portSearchTimeout = setTimeout(async () => {
                this.portSearchQuery = new AbortController();
                const signal = this.portSearchQuery.signal;
                this.portResults = await this.$API.searchTowns({
                    params: params,
                    signal,
                });
                this.loadingDestinationPorts = false;
            }, 750);
        },
        async searchVessel(val) {
            if (this.vesselLoading && this.vesselTimeout) {
                clearTimeout(this.vesselTimeout);
            }
            if (this.vesselLoading && this.vesselQuery) {
                this.vesselQuery.abort();
            }
            this.vesselLoading = true;
            this.vesselTimeout = setTimeout(async () => {
                this.vesselQuery = new AbortController();
                const signal = this.vesselQuery.signal;
                this.vessels = await this.$API.searchVessels({
                    signal,
                    params: {
                        search: val,
                    },
                });
                this.vesselLoading = false;
            }, 750);
        },
    },
    async mounted() {
        this.setDateType('2_week')
    },

    methods: {
        findSailingScheduleId(sailingScheduleId,item){
           return sailingScheduleId && item === sailingScheduleId ? 'text-green' : 'text-gray'
        } ,
        setETDData(date) {
            this.selectedSailingScheduleData.eta = date
            this.$emit('select', this.selectedSailingScheduleData)
            this.etaDialog = false
            this.selectedEtaDate = null
        },
  
        expandRow(item) {
            let findIndex = this.expanded.findIndex(x => x.id == item.id)
            if (findIndex == -1) {
                if (!item.sailingScheduleLegs) {
                    item.sailingScheduleLegs = []
                    this.getSailingScheduleLegs(item)
                }
                this.expanded = [item]
            } else {
                this.expanded.splice(findIndex, 1)
            }
        },
        formatDate(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy");
            }
            return result;
        },
        formatDateTime(date) {
            let result = null;
            if (date) {
                result = dateFormat(new Date(date), "dd-mm-yyyy HH:MM");
            }
            return result;
        },
        async canDelete(item) {
            let result = false
            if (item.source == 'LOGLIVE') {
                let exsistInBooking = await this.$API.checkManualSailingSchedule(item.id)
                if (!exsistInBooking) { result = true }
            }
            return result
        },
        async getSailingScheduleLegs(item) {
            this.loadingScheduleLegs = true
            item.sailingScheduleLegs = await this.$API.getSailingScheduleLegs(item.id)
            this.loadingScheduleLegs = false
        },
        async getSchedules() {
            if (this.params.dates.length == 2) {
                this.schedules = []
                if (this.scheduleSearchTimeout) {
                    clearTimeout(this.scheduleSearchTimeout);
                }
                if (this.loading && this.scheduleSearchQuery) {
                    this.scheduleSearchQuery.abort()
                }
                this.loading = true;
                let params = {
                    ...this.params,
                    portOfLoadValue: this.portOfLoadValue,
                    finalDestinationValue: this.tempPod ? this.tempPod : this.finalDestinationValue,
                    isExport: this.export
                };
                this.scheduleSearchTimeout = setTimeout(async () => {
                    this.scheduleSearchQuery = new AbortController();
                    const signal = this.scheduleSearchQuery.signal
                    this.schedules = await this.$API.getLogLiveSailingSchedule(
                        {
                            params: params,
                            signal
                        }
                    );
                    
                    for (let i = 0; i < this.schedules.length; i++) {
                        this.schedules[i].canDelete = await this.canDelete(this.schedules[i]);
                    }
                    if(this.tempPod){
                        this.schedules = this.schedules.filter(x => x.legs === 1)
                    }
                    this.loading = false;
                }, 300);
            } else {
                if (this.scheduleSearchTimeout) {
                    clearTimeout(this.scheduleSearchTimeout);
                }
                if (this.loading && this.scheduleSearchQuery) {
                    this.scheduleSearchQuery.abort()
                }
                this.loading = false;
            }



        },
        setDateFilter() {
            if (this.params.dates.length == 2) {
                if (this.params.dates[1] < this.params.dates[0]) {
                    let dates = [this.params.dates[1], this.params.dates[0]];
                    this.params.dates = dates;
                }
            }
            this.searchKey++
            this.dateMenu = false;
        },
        setDateType(type) {
            this.selectedDateType = type
            switch (type) {
                case 'week':
                    this.params.dates = [new Date().toISOString().substring(0, 10), new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().substring(0, 10)]
                    break;
                case '2_week':
                    this.params.dates = [new Date().toISOString().substring(0, 10), new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().substring(0, 10)]
                    break;
                case '4_week':
                    this.params.dates = [new Date().toISOString().substring(0, 10), new Date(new Date().setDate(new Date().getDate() + 28)).toISOString().substring(0, 10)]
                    break;
                case '6_week':
                    this.params.dates = [new Date().toISOString().substring(0, 10), new Date(new Date().setDate(new Date().getDate() + 42)).toISOString().substring(0, 10)]
                    break;
            }
            this.searchKey++
        },
        async createTranshipment(item){
            this.manualTranshipmentItem = item
            this.manualTranshipmentEtaModal = true
        },
        async saveTranshipment() {
            this.loading = true
            this.manualTranshipmentEtaModal = false
            this.manualTranshipmentItem.via = this.tempPod;
            this.manualTranshipmentItem.finalDestinationValue = this.finalDestinationValue;
            this.manualTranshipmentItem.direct = false;
            this.manualTranshipmentItem.legs = 2;
            this.manualTranshipmentItem.routing = '';
            this.manualTranshipmentItem.direct = 0;
            this.manualTranshipmentItem.source = 'LOGLIVE';
            delete this.manualTranshipmentItem.id
            delete this.manualTranshipmentItem.hash
            delete this.manualTranshipmentItem.sailingScheduleLegs;
            console.log('before create', this.manualTranshipmentItem);
            this.manualTranshipmentItem = await this.$API.createSailingSchedule(this.manualTranshipmentItem)
            console.log('after create', this.manualTranshipmentItem);
            this.tempPod = null
            this.loading = false
            this.getSchedules()
        },
        async selectItem(item) {
            if(!item.eta){
                //open dialog where user can add eta
                this.selectedSailingScheduleData = item
                this.etaDialog = true               
            }
            else{
                this.$emit('select', item)
            }
            console.log('item',item)
        },
        setVessel(vessel) {
            this.manualVessel.vessel = vessel;
            this.manualVessel.vesselId = vessel.id;
            this.manualVessel.vesselDescription = vessel.name;
            this.vesselModal = false;
            this.voyageNumberModal = true;
        },
        addManualSchedule() {
            this.manualVessel = {
                vesselDescription: null,
                voyage: '',
                portOfLoadValue: this.portOfLoadValue,
                finalDestinationValue: this.tempPod ? this.tempPod : this.finalDestinationValue,
                source: 'LOGLIVE',
                direct: 1,
                legs: 1,
                via: null,
                routing: 'direct'
            }
            this.manualVesselDialog = true
        },
        addSchedule(item) {
            this.newSchedule = JSON.parse(JSON.stringify(item));
            delete this.newSchedule.id;
            delete this.newSchedule.shippingLine;
            delete this.newSchedule.shippingLineId;
            delete this.newSchedule.hash;
            delete this.newSchedule.sailingScheduleLegs;
            this.newSchedule.direct = false;
            this.newSchedule.legs = 2;
            this.newSchedule.carrier = '';
            this.newSchedule.serviceCode = '';
            this.newSchedule.serviceName = '';
            this.newSchedule.via = '';
            this.newSchedule.routing = '';
            this.newSchedule.direct = 0;
            this.newSchedule.serviceName = '';
            this.newSchedule.eta = '';
            this.newSchedule.source = 'LOGLIVE';
            this.manualScheduleDialog = true;
        },
        closeScheduleDialog() {
            this.newSchedule = null
            this.manualScheduleDialog = false
        },
        async saveVesselSchedule() {
            this.savingSchedule = true
            let res = await this.$API.createVesselSchedule(this.manualVessel)
            res ? this.$message.success("Vessel Schedule Added") : this.$message.error("Error Adding Vessel Schedule");
            this.savingSchedule = false
            this.manualVesselDialog = false
            // this.manualVessel = null
            this.getSchedules()
        },
        async saveSchedule() {
            this.savingSchedule = true
            let res = await this.$API.createSailingSchedule(this.newSchedule)
            res ? this.$message.success("Sailing Schedule Added") : this.$message.error("Error Adding Sailing Schedule");
            this.savingSchedule = false
            this.manualScheduleDialog = false
            this.newSchedule = null
            this.getSchedules()
        },
        setShippingLine(item) {
            if (this.manualScheduleDialog) {
                this.newSchedule.shippingLine = item;
                this.newSchedule.shippingLineId = item.id;
                this.newSchedule.scac = item.scacCode;
            } else {
                this.manualVessel.shippingLine = item;
                this.manualVessel.shippingLineId = item.id;
                this.manualVessel.carrier = item.friendlyName ?? item.name;
                this.manualVessel.scac = item.scacCode;
            }
            this.shippingLineModal = false;
        },
        async deleteSchedule(item) {
            this.$confirm("Are you sure you want to delete this Sailing Schedule?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            }).then(async () => {
                if (item.id) {
                    await this.$API.deleteSailingSchedule(item.id);
                }
                this.getSchedules();
                this.$message.success("Successfully deleted!");
            }).catch(() => {
                this.$message.warning("Delete canceled");
            });
        },
        removeTempPod() {
            this.tempPod = null
            this.getSchedules()
        },
        selectPod(type) {
            this.podType = type
            this.podModal = true
        },
        setPort(item) {
            if (this.podType === 'temporary') {
                this.tempPod = item.locode ?? item.code;
                this.getSchedules()
            } else {
                this.newSchedule.via = item.locode ?? item.code;
                this.newSchedule.routing = item.name;
            }
            this.portSearch = null;
            this.podModal = false;
        },
    }
}   
</script>

<style>
.text-green{
    background-color: green;
}
.text-gray{
    background-color: gray;
}

</style>