<template>
  <v-row no-gutters>
      <v-col cols="12" sm="12" md="9" lg="9" class="px-2">
        <TopBanner :shipment="shipment"/>
          <v-row no-gutters>
              <v-col id="customer-management" cols="12" sm="12" md="5" lg="5" v-for="relationship in relationships" :key="relationship.id">
                  <v-subheader style="height: 24px;" class="d-flex align-center pl-2 pr-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" small outlined style="border:none;font-size: 14px" class="pl-0 pr-2" @click="routeToContact(relationship.organisationRelationship)">
                            <v-avatar size="24" v-if="relationship.customer.logo" class="mr-1" style="background: white">
                              <v-img contain :src="relationship.customer.logo"></v-img>
                            </v-avatar>
                              {{ relationship.customer.name }}
                              </v-chip>
                          </template>
                          View Profile
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <v-chip :color="getInvoiceDetails(relationship.invoiceStatus).color" x-small>{{getInvoiceDetails(relationship.invoiceStatus).name }}</v-chip>
                  </v-subheader>
                  <v-divider></v-divider>
                  <div class="pl-2 pt-1" :style="{ 'background-color': relationship.organisationRelationship && relationship.organisationRelationship.clientStatus == 'On Hold' ? 'redPop !important' : 'transparent !important' }">
                      <div v-if="relationship.organisationRelationship" style="display: flex; flex-direction: column;">
                          <span style="font-size: 12px">
                              <span style="opacity: 0.7;" class="mr-1">Client status:</span> {{ relationship.organisationRelationship.clientStatus }}
                          </span>
                          <span style="font-size: 12px">
                              <span style="opacity: 0.7;" class="mr-1">Account Reference:</span> {{ relationship.organisationRelationship.accountReference }} 
                          </span>
                          <span style="font-size: 12px">
                              <span style="opacity: 0.7;" class="mr-1">Contract Owner:</span> {{
                            shipment.incoTermContractOwner === 'buyer' ? shipment.buyer.name :
                            shipment.incoTermContractOwner === 'shipper' ? shipment.shipper.name :
                              shipment.incoTermContractOwner === 'forwarder' ? shipment.forwarder.name :
                                shipment.incoTermContractOwner === 'consignee' ? shipment.consignee.name :
                                  ''
                          }}
                          </span>
                          <span style="font-size: 12px">
                              <span style="opacity: 0.7;" class="mr-1">SL Freight Invoice To:</span> {{
                            shipment.freightCharge === 'buyer' ? shipment.buyer.name :
                            shipment.freightCharge === 'shipper' ? shipment.shipper.name :
                              shipment.freightCharge === 'forwarder' ? shipment.forwarder.name :
                                shipment.freightCharge === 'consignee' ? shipment.consignee.name :
                                  ''}}
                          </span>
                          <span style="font-size: 12px;" v-if="shipment.freightInvoiceTo && shipment.freightInvoiceTo != ''"><span style="opacity: 0.7;" class="mr-1">FF Freight Invoice To:</span> {{shipment.freightInvoiceTo }}</span>
                          <span style="font-size: 12px;" v-if="relationship.invoiceStatus == 'invoiced'"><span style="opacity: 0.7;" class="mr-1">Invoice Processed By:</span> {{relationship.invoicedBy }}</span>
                      </div>
                  </div>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4">
                <v-list dense class="my-0 py-0">
                  <v-list-item v-for="(special) in documentSpecialComments" :key="special.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ special.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="special.comment" class="text-wrap" style="font-size: 12px">
                        <b> Comment: </b> {{ special.comment }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-wrap" style="font-size: 12px" v-if="special.specialInstruction">
                        <b>Special Note: </b> {{ special.specialInstruction }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="documentSpecialComments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No Special Instructions or comments</span>
                    </v-list-item-content></v-list-item>
                </v-list>
                  <v-card style="background: transparent !important;" id="shipment-summary" flat v-if="!shipment.shipmentFile.breakBulkShipment" class="my-0 py-0">
                      <v-card-subtitle class="my-0 py-0" style="font-size: 12px">
                          <div v-if="!shipment.breakBulkShipment">
                              {{ bookings.length }} Containers - {{ uniquePallets }} Pallets
                          </div>
                          <div v-for="(summaryItem, index) in cartonSummary" :key="index">
                              <span> {{ summaryItem.count }} {{ summaryItem.packType }}</span>
                          </div>
                      </v-card-subtitle>
                      <v-card-text class="my-0 py-0">
                          <v-list class="my-0 py-0" dense>
                              <v-list-item>
                                  <v-list-item-action>
                                      <v-icon>scale</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>
                                          {{ containerNettWeight }} KG
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                          Total Nett Weight
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                                  <v-list-item-action>
                                      <v-icon>scale</v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>
                                          {{ containerGrossWeight }} KG
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                          Total Gross Weight
                                      </v-list-item-subtitle>
                                  </v-list-item-content>
                              </v-list-item>
                          </v-list>
                      </v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="12" sm="12" lg="3" md="3">
                  <v-list dense style="background: transparent !important;">
                    <v-list-item>
                      <v-list-item-action>
                      <v-icon>anchor</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ stackDates.terminal }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Planned Loads: {{ stackDates.plannedLoads }}</v-list-item-subtitle>
                      <v-list-item-subtitle>Planned Discharges: {{ stackDates.plannedDischarges }}</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="amber">widgets</v-icon>Dry Stacks</v-subheader>
                    <v-divider></v-divider>
                  
                    <v-list-item>
                      <!-- <v-list-item-action>
                      <v-icon color="blue">schedule</v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $Format.formatDate(stackDates.dryStackStart).dayMonCommaYear }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small :color="stackDates.dryStackStartConfirmed?'success':'deep-orange'">{{ stackDates.dryStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
                    </v-list-item-action>
                    </v-list-item>
                  
                    <v-list-item>
                      <!-- <v-list-item-action>
                      <v-icon color="orange">schedule</v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $Format.formatDate(stackDates.dryStackFinish).dayMonCommaYear }}
                      </v-list-item-title>
                      <v-list-item-subtitle> Stacks Close</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small :color="stackDates.dryStackFinishConfirmed?'success':'deep-orange'">{{ stackDates.dryStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
                    </v-list-item-action>
                    </v-list-item>
                  
                    <v-divider></v-divider>
                    <v-subheader style="font-size: 16px"><v-icon class="mr-2" color="blue">ac_unit</v-icon>Reefer Stacks</v-subheader>
                    <v-divider></v-divider>
                  
                    <v-list-item>
                      <!-- <v-list-item-action>
                      <v-icon color="blue">schedule</v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $Format.formatDate(stackDates.reeferStackStart).dayMonCommaYear }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Stacks Open</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small :color="stackDates.reeferStackStartConfirmed?'success':'deep-orange'">{{ stackDates.reeferStackStartConfirmed?'Firm':'Provisional' }}</v-chip>
                    </v-list-item-action>
                    </v-list-item>
                  
                    <v-list-item>
                      <!-- <v-list-item-action>
                      <v-icon color="orange">schedule</v-icon>
                    </v-list-item-action> -->
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $Format.formatDate(stackDates.reeferStackFinish).dayMonCommaYear }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Stacks Close</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip small :color="stackDates.reeferStackFinishConfirmed?'success':'deep-orange'">{{ stackDates.reeferStackFinishConfirmed?'Firm':'Provisional' }}</v-chip>
                    </v-list-item-action>
                    </v-list-item>
                  
                  </v-list>
              </v-col>
          </v-row>
          <div>
              <!-- <v-subheader>
                  <v-icon small left>category</v-icon>Products
              </v-subheader>
              <v-divider></v-divider> -->
              <ProductBreakdownTable :transparent="true" :headers="productSummaryHeaders" :items="productSummary" :tableKey="productKey" :loading="productsLoading" :height="'100%'"/>
          </div>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" class="pr-2" style="border-left: 0.5px solid rgb(82, 82, 82);">
          <v-subheader>
              <v-icon small left>description</v-icon>Documentation
          </v-subheader>
          <v-divider></v-divider>
          <v-list dense style="background-color: transparent !important;">
              <v-list-item v-for="document in summarisedDocuments" :key="document.id" @click="document.count > 1 ? openDocumentSummary(document) : ''" 
                  style="color: #ffffff !important" class="mt-1" :disabled="document.count == 1" :class="document.count == 1 ? 'singleDoc' : ''">
                  <v-list-item-content class="text-wrap">
                      <v-list-item-title style="text-wrap: auto;">
                          <b>{{ document.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="document.count === 1 && document.reference" style="font-size: 11px">
                        Reference: {{ document.reference }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="document.count === 1 && document.submittedDate" style="font-size: 11px">
                        Submitted Date: {{ document.submittedDate }}
                       </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="document.count === 1 && document.completedDate " style="font-size: 11px">
                        Completed Date: 
                        <span>{{ document.completedDate }}</span>
                      </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                      <v-row justify="center" class="ml-1">
                          <v-tooltip top v-for="status in document.statuses" :key="status.index">
                              <template v-slot:activator="{ on }">
                                  <v-chip v-on="on" class="mx-1 my-1" small pill outlined :color="$Format.getStatusDetail(status.status).color">
                                      <v-icon small left>{{$Format.getStatusDetail(status.status).icon}}</v-icon>
                                      {{ status.count }}
                                  </v-chip>
                              </template>
                              {{ status.status }}
                          </v-tooltip>
                      </v-row>
                  </v-list-item-action>
              </v-list-item>
          </v-list>
      </v-col>
  </v-row>
</template>

<script>
import ProductBreakdownTable from "../../SystemComponents/ProductBreakdownTable.vue"
import TopBanner from "./TopBanner.vue"
export default {
components: {
    ProductBreakdownTable,
    TopBanner
},
props: {
    shipment: {
        type: Object,
        required: false
    },
    shipmentItems: {
        type: Array,
        required: false
    },
    productSummary: {
        type: Array,
        required: false
    },
    documents: {
        type: Array,
        required: false
    },
    relationships: {
        type: Array,
        required: false
    },
    bookings: {
        type: Array,
        required: false
    },
    stackDates: {
        type: Object,
        required: false
    },
},
data: () => ({
    selectedType: 'shipment',
    productsLoading: false,
    productKey: 0,
    productSummaryHeaders: [
      {
        text: "Product",
        value: "productName",
        align: "center",
      },
      {
        text: "Variety",
        value: "varietyName",
        align: "center",
      },
      {
        text: "Pack Code",
        value: "packCode",
        align: "center",
      },
      {
        text: "Grade/Class",
        value: "gradeClassCode",
        align: "center",
      },
      {
        text: "Count/Size",
        value: "countSize",
        align: "center",
      },
      {
        text: "No. Cartons",
        value: "noCartons",
        align: "center",
      },
      {
        text: "Percentage",
        value: "percentage",
        align: "center",
      },
      // {
      //   text: "Price per Carton",
      //   value: "cartonPrice",
      //   align: "center",
      // },
      // {
      //   text: "Price per KG",
      //   value: "kgPrice",
      //   align: "center",
      // },
    ],
    invoiceStatusOptions: [
      { name: 'Not Ready', value: 'NOTREADY', color: 'grey', icon: 'schedule' },
      { name: 'Ready to Invoice', value: 'READY', color: '#DC7633', icon: 'pending' },
      { name: 'Invoiced', value: 'INVOICED', color: '#5DADE2 ', icon: 'paid' },
      { name: 'Paid', value: 'PAID', color: '#229954', icon: 'task_alt' },
    ],
}),
watch: {
    productSummary: {
        handler() {
            this.productKey++;
            this.productsLoading = false;
        },
        deep: true
    }
},
created() {
},
computed: {
  documentSpecialComments() {
      let filter = this.documents.filter(x => x.comment || x.specialInstruction)
      let uniqueDocuments = [
        ...new Set(
          filter
            .map((x) => x.locationDocumentId)
        )
      ];
      let result = []
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = filter.find(x => x.locationDocumentId == uniqueDocuments[i])
        let obj = {
          id: document.id,
          name: document.locationDocument.name,
          comment: document.comment,
          specialInstruction: document.specialInstruction,
        };
        result.push(obj);
      }
      return result
    },
    uniquePallets() {
      let pallets = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerPallets)
      );
      if (pallets.length > 0) {
        return [...new Set(pallets.map((x) => x.sscc))].length;
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) => x.containerProducts.filter((y) => y.pallets))
        );
        return products.map((x) => x.pallets).reduce((a, b) => a + b, 0);
      }
    },
    containerNettWeight() {
      let pallets = this.pallets;
      if (pallets.length > 0) {
        return pallets
          .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) =>
            x.containerProducts.filter((y) => y.nettWeight)
          )
        );
        return products
          .map((x) => Math.round(parseFloat(x.nettWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    containerGrossWeight() {
      let pallets = this.pallets;
      if (pallets.length > 0) {
        return pallets
          .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      } else {
        let products = [].concat.apply(
          [],
          this.bookings.map((x) =>
            x.containerProducts.filter((y) => y.grossWeight)
          )
        );
        return products
          .map((x) => Math.round(parseFloat(x.grossWeight) * 100) / 100)
          .reduce((a, b) => a + b, 0)
          .toFixed(2);
      }
    },
    cartonSummary() {
      let palletPacktypes = [...new Set(this.pallets.map((x) => x.packType))];
      let result = [];
      for (let i = 0; i < palletPacktypes.length; i++) {
        let pallets = this.pallets.filter(
          (x) => x.packType == palletPacktypes[i]
        );
        let cartons = pallets
          .map((x) => parseInt(x.noCartons))
          .reduce((a, b) => a + b, 0);
        result.push({
          packType: palletPacktypes[i] ?? "CARTON" + "(S)",
          count: cartons,
        });
      }
      return result;
    },
    pallets() {
      let pallets = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerPallets)
      );
      return pallets;
    },
    summarisedDocuments() {
      let result = [];
      let statuses = [...new Set(this.documents.map((x) => x.status))];
      let uniqueDocuments = [
        ...new Set(
          this.documents.filter((x) => statuses.includes(x.status)).map((x) => x.locationDocumentId))];
        for (let i = 0; i < uniqueDocuments.length; i++) {
            let document = this.documents.find((x) => x.locationDocumentId == uniqueDocuments[i]
        );
        let obj = {
            id: document.id,
            name: document.locationDocument.name,
            count: this.documents.filter(
              (x) => x.locationDocumentId == uniqueDocuments[i]
            ).length,
            completedDue: document.completedDue,
            completedDate: document.completedDate,
            submittedDue: document.submittedDue,
            submittedDate: document.submittedDate,
            reference: document.reference,
            statuses: statuses.map((x, index) => ({
                    index: index,
                    status: x,
                    count: this.documents.filter((y) =>y.locationDocumentId == uniqueDocuments[i] && y.status == x).length
                }
            )).filter((x) => x.count > 0),
        };
        result.push(obj);
      }
      return result;
    },
},
methods: {
    routeToContact(contact) {
      this.$router.push('/contact/' + contact.id)
    },
    getInvoiceDetails(status) {
      let find = this.invoiceStatusOptions.find(x => x.value == status);
      return find ? {color: find.color, name: find.name} : {color: 'grey', name: null}
    },
    viewShipmentDetails() {
        this.selectedType = 'shipment';
        this.$emit('viewShipmentDetails');
    },
    documentationSummary() {
      let uniqueStatuses = [...new Set(this.documents.map((x) => x.status))];
      uniqueStatuses = uniqueStatuses.map((x) => ({
        status: x,
        count: this.documents.filter((y) => y.status == x).length,
      }));
      return uniqueStatuses;
    },
    openDocumentSummary(document) {
        this.$emit('openDocumentSummary', document);
    },
}
}

</script>

<style scoped>
</style>