<template>
  <div>
    <v-card
      class="infoCards"
      :style="{ height: height, contain: 'content' }"
      flat
    >
      <div
        style="z-index: 888; position: absolute; left: 4px; top: 4px"
        v-if="!loadingMap"
      >
        <v-tooltip top v-for="source in containerRoutes" :key="source.id">
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              small
              v-on="on"
              :color="
                activeSource && activeSource.id == source.id
                  ? 'secondary'
                  : 'greyRaised'
              "
              @click="setActiveSource(source)"
            >
              <v-icon
                small
                left
                :color="
                  activeSource && activeSource.id == source.id
                    ? 'white'
                    : getStatusColor(source.status)
                "
                >{{ getStatusIcon(source.status) }}</v-icon
              >

              {{ source.provider }}
              <v-icon
                right
                v-if="activeSource && activeSource.id == source.id"
                small
                >check</v-icon
              >
            </v-chip>
          </template>
          <span
            >Data source: {{ source.provider }}
            <span
              v-if="activeSource && activeSource.id == source.id"
              class="ml-1"
              >(Active)</span
            ></span
          >
        </v-tooltip>
        <v-tooltip top v-if="logliveSubscriptionAvailable">
          <template v-slot:activator="{ on }">
            <v-chip
              class="mx-1"
              small
              v-on="on"
              :color="'primary'"
              @click="subscribeConfirmationModal = true"
            >
              <v-icon small left>add</v-icon>
              LogLive Tracking
            </v-chip>
          </template>
          <span>Subscribe to LogLive Tracking</span>
        </v-tooltip>
      </div>

      <div
        class="blurOverlay"
        v-if="
          subscriptionRequired && !subscribingContainer && container.containerNo
        "
      >
        <v-chip style="border: none" outlined>
          <v-icon color="amber" left>lock </v-icon>
          <span style="font-weight: bold; font-size: 14px"
            >Subscription Required</span
          >
        </v-chip>
        <v-btn
          style="text-transform: none"
          rounded
          color="primary"
          class="mt-2"
          @click="subscribeConfirmationModal = true"
        >
          Subscribe Now
        </v-btn>
      </div>
      <div
        class="blurOverlay"
        v-else-if="
          subscriptionRequired &&
          !subscribingContainer &&
          !container.containerNo
        "
      >
        <v-chip style="border: none" outlined>
          <v-icon color="red" left>cancel </v-icon>
          <span style="font-weight: bold; font-size: 14px"
            >No container number specified</span
          >
        </v-chip>
      </div>
      <div class="blurOverlay" v-else-if="subscribingContainer">
        <v-progress-circular indeterminate></v-progress-circular>
        <span>Subscribing to tracking...</span>
      </div>

      <div class="overlayMap" v-else-if="loadingMap || noTrackingData">
        <v-progress-circular
          indeterminate
          v-if="loadingMap"
        ></v-progress-circular>
        <span style="font-weight: bold; font-size: 14px">{{
          loadingMapMessage
        }}</span>
      </div>
      <div
        class="overlayLegs"
        v-if="
          activeSource && !hideLegs &&
          activeSource.legBreakdown &&
          activeSource.legBreakdown.length > 0
        "
      >
        <v-card
          style="
            width: 100%;
            max-height: 80%;
            border: 1px solid rgba(255, 255, 255, 0.27);
          "
          class="glassCard"
        >
          <v-list
            dense
            subheader
            class="mx-0 px-0"
            style="max-height: 80%; overflow-y: auto; background: transparent"
          >
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px"
              >Legs <v-spacer></v-spacer>
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-for="leg in activeSource.legBreakdown"
              :key="leg.leg"
            >
              <v-list-item-action class="mx-0 px-0 mr-2 pr-1">
                <v-chip color="secondary">{{ leg.leg }}</v-chip>
              </v-list-item-action>
              <v-list-item-content class="ml-1">
                <v-list-item-title style="font-size: 13px">
                  <v-chip
                    color="greyRaised"
                    small
                    class="pl-0 pr-2"
                    style="font-size: 12px"
                  >
                    <v-avatar size="64" class="mr-2">
                      <v-img
                        :src="`https://cdn.loglive.io/flags/4x3/${leg.start
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ leg.start }}
                  </v-chip>
                  <v-icon small class="mx-1">arrow_forward</v-icon>
                  <v-chip
                    color="greyRaised"
                    small
                    class="pl-0 pr-2"
                    style="font-size: 12px"
                  >
                    <v-avatar size="64" class="mr-2">
                      <v-img
                        :src="`https://cdn.loglive.io/flags/4x3/${leg.end
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ leg.end }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px"
                  >ETD: {{ $Format.formatDate(leg.startDate).dateTimeDefault }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px"
                  >ETA: {{ $Format.formatDate(leg.endDate).dateTimeDefault }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px"
                  >Duration: {{ leg.duration }} Day<span v-if="leg.duration > 1"
                    >s</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px" v-if="leg.vessel">
                  <v-icon small class="mr-1" color="grey"
                    >directions_boat</v-icon
                  >
                  {{ leg.vessel }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <div class="overlayEvents" v-if="activeSource">
        <v-card
          style="
            height: 100%;
            width: 100%;
            border: 1px solid rgba(255, 255, 255, 0.27);
          "
          class="glassCard"
        >
          <v-list
            dense
            subheader
            class="mx-0 px-0"
            style="max-height: 100%; overflow-y: auto; background: transparent"
          >
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px"
              >{{ container.containerNo }} <v-spacer></v-spacer>
              <v-chip
                small
                outlined
                :color="getStatusColor(activeSource.status)"
              >
                <v-icon left small>{{
                  getStatusIcon(activeSource.status)
                }}</v-icon>
                {{ formatStatus(activeSource.status) }}</v-chip
              >
            </v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-if="activeSource.status == 'ERROR' && activeSource.message"
              style="height: 40px"
            >
              <v-list-item-action>
                <v-icon color="grey">contact_support</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px" class="text-wrap">
                  {{ activeSource.message }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Message Detail
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="activeSource.status == 'ERROR'"
              @click="subscribeConfirmationModal = true"
            >
              <v-list-item-action>
                <v-icon color="blue">autorenew</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Retry Subscription </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="activeSource.billOfLadingNo"
              style="height: 40px"
            >
              <v-list-item-action>
                <v-icon color="grey">confirmation_number</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ activeSource.billOfLadingNo }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Bill of Lading
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="activeSource.bookingNo" style="height: 40px">
              <v-list-item-action>
                <v-icon color="grey">confirmation_number</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ activeSource.bookingNo }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Booking Number
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="activeSource.ETD" style="height: 40px">
              <v-list-item-action>
                <v-icon v-if="activeSource.isATD" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="blue">pending</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ $Format.formatDate(activeSource.ETD).dayMonthYear }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Departure Date <i v-if="activeSource.isATD">(Actual)</i>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="activeSource.ETA" style="height: 40px">
              <v-list-item-action>
                <v-icon v-if="activeSource.isATA" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="blue">pending</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ $Format.formatDate(activeSource.ETA).dayMonthYear }}
                </v-list-item-title>
                <v-list-item-subtitle style="font-size: 11px">
                  Arrival Date <i v-if="activeSource.isATA">(Actual)</i>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px">Events</v-subheader>
            <v-divider></v-divider>
            <v-list-item
              v-if="
                !activeSource ||
                !activeSource?.events ||
                (activeSource?.events && activeSource?.events.length == 0)
              "
            >
              <v-list-item-content class="text-center">
                <span style="color: grey"> No events available </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="event in activeSource?.events" :key="event.id">
              <v-list-item-action class="mx-0 px-0">
                <v-icon :color="event.isActual ? 'success' : 'blue'" small
                  >fiber_manual_record</v-icon
                >
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px" class="text-wrap">{{
                  event.description
                }}</v-list-item-title>
                <v-list-item-subtitle
                  v-if="event.vesselName"
                  class="text-wrap"
                  style="font-size: 11px"
                >
                  <v-icon x-small color="grey" class="mr-1"
                    >directions_boat</v-icon
                  >
                  {{ event.vesselName }}
                  <span v-if="event.voyageNo"> {{ event.voyageNo }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else-if="event.modeOfTransport"
                  style="font-size: 11px"
                >
                  <v-icon x-small color="grey" class="mr-1">{{
                    getTransportIcon(event.modeOfTransport)
                  }}</v-icon>
                  {{ event.modeOfTransport }}
                </v-list-item-subtitle>
                <v-list-item-subtitle style="font-size: 11px">{{
                  $Format.formatDate(event.date).dateTimeDefault
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip
                  color="greyRaised"
                  small
                  class="pl-0 pr-2"
                  style="font-size: 12px"
                >
                  <v-avatar size="64" class="mr-2">
                    <v-img
                      :src="`https://cdn.loglive.io/flags/4x3/${event.location
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                  {{ event.location }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </div>
      <gmap-map
        :key="refreshMap"
        :zoom.sync="zoom"
        class="ma-0 pa-0 theMap"
        id="map"
        :options="{ styles: mapStyle, disableDefaultUI: true }"
        :center.sync="mapCenter"
        ref="mapRef"
        style="width: 100%; min-height: 600px"
      >
        <div v-if="activeSource">
          <gmap-polyline
            v-for="(path, index) in activeSource.vesselRoute"
            :key="index"
            :path="path.route"
            :editable="false"
            ref="polyline"
            :options="{
              strokeColor: legColours[index] ?? '#2196f3',
              strokeOpacity: 0.9,
              strokeWeight: 3,
            }"
          >
          </gmap-polyline>
          <gmap-custom-marker
            v-if="activeSource && activeSource.vessel"
            alignment="center"
            :marker="{
              lat: activeSource.vessel.latitude,
              lng: activeSource.vessel.longitude,
            }"
          >
            <div>
                <div class="pulse">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"
                  color="teal" @click="displayVessel = !displayVessel"
                  :style="{
                    rotate: `${activeSource.vessel.course - 45}deg`,
                    'z-index': 10000,
                  }"
                  >near_me</v-icon
                >
                    </template>
                    <span>{{ activeSource.vessel.name }} (Updated: {{ $Format.formatDate(activeSource.vessel.lastPositionTime).dateTimeDefault }})</span>
                  </v-tooltip>
               
              </div>
            </div>
          </gmap-custom-marker>
     
         
          <gmap-custom-marker
            v-else-if="activeSource && activeSource.position"
            alignment="center"
            :marker="{
              lat: activeSource.position.coordinates[1],
              lng: activeSource.position.coordinates[0],
            }"
          >
            <div>
              <div class="pulse">
                <v-icon
                  color="tertiary"
                  :style="{
                    'z-index': 10000,
                  }"
                  >fiber_manual_record</v-icon
                >
              </div>
            </div>
          </gmap-custom-marker>

          <gmap-custom-marker
            v-for="location in uniqueLocations.filter(x=>x.marker && x.marker.lat && x.marker.lng)"
            :key="location.id"
            :offsetY="-12"
            alignment="center"
            :marker="location.marker"
          >
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-chip color="greyRaised" small class="pl-0 pr-2" v-on="on">
                    <v-avatar size="64" class="mr-2">
                      <v-img
                        :src="`https://cdn.loglive.io/flags/4x3/${location.location
                          .substring(0, 2)
                          .toLowerCase()}.svg`"
                      ></v-img>
                    </v-avatar>
                    {{ location.location }}
                  </v-chip>
                </template>
                <span>{{ location.location }} ({{ location.type }})</span>
              </v-tooltip>
            </div>
            <div class="map-marker"></div>
          </gmap-custom-marker>
        </div>
      </gmap-map>
    </v-card>

    <v-dialog v-model="subscribeConfirmationModal" width="400px">
      <v-card style="border-radius: 0px">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to subscribe to the tracking service for
            this container?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="subscribeConfirmationModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="subscribeContainer()"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
import GmapCustomMarker from "vue2-gmap-custom-marker";
import { getGoogleMapsAPI } from "gmap-vue";
export default {
  name: "TrackingContainer",
  props: {
    container: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: "100%",
    },
    hideLegs: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GmapCustomMarker,
  },
  data: () => ({
    activeSource: null,
    containerRoutes: [],
    displayVessel: true,
    legColours: ["#2196f3", "#4caf50", "#ff9800", "#f44336"],
    loadingInitialSubscription: false,
    loadingMap: true,
    loadingMapMessage: "Loading...",
    mapCenter: { lat: 12.322447, lng: 26.7587 },
    noTrackingData: false,
    zoom: 1.8,
    mapStyle: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#c1d6e0",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#3e3e3e",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
    ],
    logliveSubscriptionAvailable: false,
    pollSubscription: null,
    refreshMap: 100,
    subscriptionRequired: false,
    subscribingContainer: false,
    subscribeConfirmationModal: false,
    uniqueLocations: [],
  }),
  computed: {
    google: getGoogleMapsAPI,
    arrivalPort() {
      let arrivalPort = {};
      if (this.activeSource && this.activeSource.events) {
        this.activeSource.events.forEach((event) => {
          if (
            event.typeCode == "ARRI" &&
            event.description == "Vessel arrived"
          ) {
            arrivalPort = event;
          }
        });
      }

      return arrivalPort;
    },
    departurePort() {
      let departurePort = {};
      if (this.activeSource && this.activeSource.events) {
        this.activeSource.events.forEach((event) => {
          if (
            event.typeCode == "DEPA" &&
            event.description == "Vessel departed"
          ) {
            departurePort = event;
          }
        });
      }
      return departurePort;
    },
  },
  watch: {
    "container.id": {
      handler() {
        this.loadingMapMessage = "Loading...";
        if (this.container && this.container.id) {
          this.getContainerTracking(this.container);
        }
        if (this.pollSubscription) {
          clearInterval(this.pollSubscription);
          this.pollSubscription = null;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.$socket.onmessage = (data) => {
      let message = JSON.parse(data.data);
      console.log("Message received", message);
      if (typeof message == "string") {
        message = JSON.parse(message);
      }
      if (
        message &&
        message.type == "tracking_created" &&
        message.bookingContainerId == this.container.id
      ) {
        this.getContainerTracking(this.container);
      }
    };
  },
  unmounted() {
    if (this.pollSubscription) {
      clearInterval(this.pollSubscription);
      this.pollSubscription = null;
    }
  },
  methods: {
    formatStatus(status) {
      switch (status) {
        case "PENDING":
          return "Pending";
        case "IN_PROGRESS":
          return "In Progress";
        case "COMPLETE":
          return "Complete";
        case "ERROR":
          return "Error";
        default:
          return "Unknown";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "PENDING":
          return "location_on";
        case "IN_PROGRESS":
          return "mode_of_travel";
        case "COMPLETE":
          return "where_to_vote";
        case "ERROR":
          return "error";
        default:
          return "not_listed_location";
      }
    },
    getStatusColor(status) {
      switch (status) {
        case "PENDING":
          return "orange";
        case "IN_PROGRESS":
          return "blue";
        case "COMPLETE":
          return "success";
        case "ERROR":
          return "red";
        default:
          return "grey";
      }
    },
    getTransportIcon(mode) {
      switch (mode) {
        case "SEA":
          return "directions_boat";
        case "AIR":
          return "flight";
        case "RAIL":
          return "train";
        case "ROAD":
          return "local_shipping";
        case "TRUCK":
          return "local_shipping";
        default:
          return "directions";
      }
    },
    getContainerDirection(container) {
      if (container.position && container.position.coordinates) {
        let start = {
          lat: container.position.coordinates[1],
          lng: container.position.coordinates[0],
        };
        if (
          container.vesselRoute &&
          container.vesselRoute.length > 0 &&
          container.vesselRoute[0]?.route.length > 0
        ) {
          let last = container.vesselRoute[0].route.length - 1;
          let end = {
            lat: container.vesselRoute[0].route[last].lat,
            lng: container.vesselRoute[0].route[last].lng,
          };
          return this.getRotation(start, end);
        }
      }
    },
    getRotation(start, end) {
      let radians = getAtan2(end.lng - start.lng, end.lat - start.lat);
      function getAtan2(x, y) {
        return Math.atan2(x, y);
      }
      let direction = radians * (180 / Math.PI);
      direction = direction - 45; //Icon is rotated by 45deg so we need to adjust
      return direction;
    },
    async getContainerTracking(item) {
      this.loadingMap = true;
      this.loadingMapMessage = "Loading...";
      this.subscriptionRequired = false;
      this.logliveSubscriptionAvailable = false;
      let result = await this.$API.getAllContainerTracking(item.id);
      // result = [...result, ...result]
      this.containerRoutes = result;
      this.activeSource = null;
      let findLoglive = result.find((x) => x.provider == "LOGLIVE");
      this.logliveSubscriptionAvailable = findLoglive ? false : true;
      for (let i = 0; i < result.length; i++) {
        if (!this.activeSource) {
          this.setActiveSource(result[i]);
        }
        if (result[i].position && result[i].position.coordinates) {
          this.mapCenter = {
            lat: result[i].position.coordinates[1],
            lng: result[i].position.coordinates[0],
          };
        }
        this.zoom = 2;
        this.loadingMapMessage = "Success!";
        this.loadingMap = false;
        this.noTrackingData = false;
      }
      this.loadingMap = false;
      if (!result || result.length == 0) {
        this.subscriptionRequired = true;
      } else {
        if (this.pollSubscription) {
          clearInterval(this.pollSubscription);
          this.pollSubscription = null;
        }
      }
    },
    setActiveSource(source) {
      this.activeSource = source;
      if (source.position && source.position.coordinates) {
        this.mapCenter = {
          lat: source.position.coordinates[1],
          lng: source.position.coordinates[0],
        };
      }
      let positions = [
        ...new Set(
          this.activeSource.events.map((x) => x.location).filter(Boolean)
        ),
      ];
      let POLIndex = positions.indexOf(this.activeSource.pol);
      let PODIndex = positions.indexOf(this.activeSource.pod);
      let TSPorts = positions.filter((x, i) => i > POLIndex && i < PODIndex);
      this.uniqueLocations = positions.map((x) => {
        let find = this.activeSource.events.find((y) => y.location == x);
        let index = positions.indexOf(x);
        return {
          id: find.id,
          location: x,
          type:
            index == POLIndex
              ? "POL"
              : index == PODIndex
              ? "POD"
              : index < POLIndex
              ? "Pre-POL"
              : index > PODIndex
              ? "Post-POD"
              : TSPorts.includes(x)
              ? "Transshipment Port: " + (TSPorts.indexOf(x) + 1)
              : "Unknown",
          marker: {
            lat: find.lat,
            lng: find.lng,
          },
        };
      });
      let legs = [];
      for (let i = 0; i < this.uniqueLocations.length - 1; i++) {
        let startLocation = this.activeSource.events.filter(
          (x) => x.location == this.uniqueLocations[i].location
        );
        startLocation = startLocation[startLocation.length - 1];
        let endLocation = this.activeSource.events.filter(
          (x) => x.location == this.uniqueLocations[i + 1].location
        );
        endLocation = endLocation[0];
        let leg = {
          leg: i + 1,
          start: this.uniqueLocations[i].location,
          startPosition: this.uniqueLocations[i].marker,
          startDate: startLocation.date,
          end: this.uniqueLocations[i + 1]
            ? this.uniqueLocations[i + 1].location
            : this.uniqueLocations[i].location,
          endPosition: this.uniqueLocations[i + 1]
            ? this.uniqueLocations[i + 1].marker
            : this.uniqueLocations[i].marker,
          endDate: endLocation.date,
          duration: Math.round(
            (new Date(endLocation.date).getTime() -
              new Date(startLocation.date).getTime()) /
              (1000 * 60 * 60 * 24)
          ),
          vessel: startLocation.vesselName ? startLocation.vesselName : null,
          vesselIMO: startLocation.vesselIMO ? startLocation.vesselIMO : null,
        };
        if (leg.vessel && leg.voyageNo) {
          leg.vessel = leg.vessel + " " + leg.voyageNo;
        }
        legs.push(leg);
      }
      this.activeSource.legBreakdown = legs;
      this.activeSource.legs = legs.length;
      this.zoom = 2;
      this.refreshMap++;
    },
    async subscribeContainer() {
      this.subscribeConfirmationModal = false;
      this.subscribingContainer = true;
      let subscription = await this.$API.subscribeContainer(this.container.id);

      console.log("Initial subscription", new Date());
      if (subscription) {
        console.log("Subscription", subscription);
        this.$socket.send(
          JSON.stringify({
            action: "join",
            room: "tracking_" + subscription.messageId,
            user: {
              id: this.$store.state.user.id,
              name:
                this.$store.state.user.firstname +
                " " +
                this.$store.state.user.surname,
              avatar: this.$store.state.user.avatar,
              company: this.$store.state.currentOrg.name,
            },
          })
        );
        this.loadingInitialSubscription = true;
        this.loadingMap = true;
        this.loadingMapMessage = "Waiting for initial tracking data...";
        this.subscriptionRequired = false;
        // this.pollSubscription = setInterval(()=>{
        //   console.log('Getting subscription data...', new Date())
        // this.loadingInitialSubscription = false
        //   this.getContainerTracking(this.container);
        // }, 5000)
      }
      // if(subscription) {
      //   this.loadingMapMessage = 'Successfully subscribed to container. Building Tracking Data...'
      //   await new Promise(resolve => setTimeout(resolve, 5000))
      //   this.loadingMapMessage = 'Getting tracking data...'
      //   let retry = await this.$API.getContainerTracking(this.container.id)
      //   if(retry) {
      //     if(retry.position && retry.position.coordinates) {
      //       this.mapCenter = {lat: retry.position.coordinates[1], lng: retry.position.coordinates[0]}
      //       this.zoom = 2
      //       this.loadingMapMessage = 'Success!'
      //       this.containerRoutes.push(retry)
      //       this.loadingMap = false
      //       this.noTrackingData = false
      //     } else {
      //       this.loadingMapMessage = 'Tracking Data unavailable.'
      //       this.noTrackingData = true
      //       this.loadingMap = false
      //     }
      //   } else {
      //     await new Promise(resolve => setTimeout(resolve, 10000))
      //     let retryAgain = await this.$API.getContainerTracking(this.container.id)
      //     if(retryAgain) {
      //       if(retryAgain.position && retryAgain.position.coordinates) {
      //       this.mapCenter = {lat: retryAgain.position.coordinates[1], lng: retryAgain.position.coordinates[0]}
      //       this.zoom = 2
      //       this.loadingMapMessage = 'Success!'
      //       this.containerRoutes.push(retryAgain)
      //       this.loadingMap = false
      //       this.noTrackingData = false
      //     } else {
      //       this.loadingMapMessage = 'Tracking Data unavailable.'
      //       this.noTrackingData = true
      //       this.loadingMap = false
      //     }
      //     } else {
      //     this.loadingMapMessage = 'Could not get tracking data. Please try again later'
      //     this.noTrackingData = true
      //     this.loadingMap = false
      //     }
      //   }
      //   // this.containerRoutes.push(subscription)
      // }
      this.subscribingContainer = false;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.glassCard {
  background-color: rgba(40, 40, 40, 0.75) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}

.theMap ::v-deep.gm-style:nth-child(3) {
  border: none !important;
  border-color: transparent !important;
}
.overlayMap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.overlayEvents {
  position: absolute;
  top: 0;
  right: 0;
  width: 25%;
  min-width: 270px;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.overlayLegs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 25%;
  min-width: 270px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pulse {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(0, 125, 92, 0.1);
  transform: scale(1);
  animation: pulsing 2s infinite;
}

@keyframes pulsing {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 125, 92, 0.7);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 125, 92, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 125, 92, 0);
  }
}
.blurOverlay {
  background: rgba(141, 141, 141, 0.75);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>