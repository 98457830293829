<template>
    <div class="innerContent">
        <v-row no-gutters class="pa-2">
            <v-col cols="12" sm="12" md="4" lg="4">
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-card style="height: 100%;" class="infoCards" flat >
                            <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">verified</v-icon>Status
                            </v-subheader>
                            <v-divider></v-divider> 
                            <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4" lg="4" class="pt-1">
                              <v-list dense style="background: transparent !important;" class="pa-0">
                                <v-list-item class="mt-0 pb-0 pt-0">
                                      <v-list-item-content class="pl-2">
                                          <v-list-item-title>
                                              <v-chip x-small :color="getStatusColor(container.stockStatus, 'stockStatus')" class="px-2">
                                                  {{ container.stockStatus }}
                                              </v-chip>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              Stock Status
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item class="mt-0 pt-0 pb-0">
                                      <v-list-item-content class="pl-2">
                                          <v-list-item-title>
                                            <v-chip x-small :color="getStatusColor(container.status, 'status')" class="px-2">
                                              {{  container.status }}
                                            </v-chip>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              Container Status
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                  </v-list-item>
                                  <v-list-item class="mt-0 pt-0 pb-0">
                                      <v-list-item-content class="pl-2">
                                          <v-list-item-title>
                                            <v-chip x-small :color="getStatusColor(container.transportStatus, 'transportStatus')" class="px-2">
                                              {{  container.transportStatus }}
                                            </v-chip>
                                          </v-list-item-title>
                                          <v-list-item-subtitle>
                                              Transport Status
                                          </v-list-item-subtitle>
                                      </v-list-item-content>
                                  </v-list-item>
                              </v-list>
                            </v-col>
                            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex pt-1" style="height: 100%;">
                              <div class="d-flex" style="flex-direction: column;">
                                  <span style="opacity: 0.8;">Pallets</span>
                                <v-divider class="mb-1"></v-divider>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" class="mb-1">
                                  <v-icon class="mr-1" small color="success">check</v-icon>
                                  <b class="mr-1">{{container.loadedPallets}}</b> Loaded
                                </span>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;" class="mb-1">
                                    <v-icon class="mr-1" small color="orange">update</v-icon>
                                    <b class="mr-1">{{container.allocatedPallets}}</b> Allocated
                                </span>
                                <span style="font-size: 0.625rem; opacity: 0.7; display: flex; align-items: center;">
                                  <v-icon class="mr-1" small color="danger">error_outline</v-icon>
                                  <b class="mr-1">{{container.issuePallets}}</b> Issues
                                </span>
                              </div>
                              <v-spacer></v-spacer>
                              <div class="d-flex align-center">
                                <div class="mr-2 d-flex align-center" style="flex-direction: column;">
                                    <span style="opacity: 0.8;">Allocated</span>
                                    <v-progress-circular rotate="270" :size="80" :width="12" :value="averageProgress"
                                    :color=" averageProgress == 0 ? 'grey' : averageProgress < 20 ? 'red' : averageProgress < 50 ? 'orange' : averageProgress < 100 ? 'blue' : 'success' ">
                                      {{ averageProgress + '%' }}
                                    </v-progress-circular>
                                </div>
                                <div class="mr-1 d-flex align-center" style="flex-direction: column;">
                                    <span style="opacity: 0.8;">Loaded</span>
                                    <v-progress-circular rotate="270" :size="80" :width="12" :value="loadProgress"
                                    :color=" loadProgress == 0 ? 'grey' : loadProgress < 20 ? 'red' : loadProgress < 50 ? 'orange' : loadProgress < 100 ? 'blue' : 'success' ">
                                      {{ loadProgress + '%' }}
                                    </v-progress-circular>
                                </div>
                              </div>
                            </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-2">
                        <v-card style="height: 100%;" class="infoCards" flat >
                            <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">tag</v-icon>References
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    <v-list-item class="mb-0">
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.containerNo ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              Container No
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.sealNo ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Seal No
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    <v-list-item class="mb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.customerContainerRef ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Customer Ref
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.ctoNo ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            CTO No
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="12" md="4" lg="4">
                                    <v-list-item class="mb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.billOfLadingNo ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Bill of Lading
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ order.contractNumber ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Contract No
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12" class="pt-2">
                        <v-card style="height: 100%;" class="infoCards" flat >
                            <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">ac_unit</v-icon>Regime
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-row no-gutters style="padding: 12px 0 14px 0;">
                                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex justify-center align-center">
                                    <span style="font-size: 16px !important;">{{ order.regime?.code }}</span>
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex justify-center align-center">
                                    <v-icon small color="grey">thermostat</v-icon><div class="opacDim mr-1">Set Point:</div> {{ order.regime?.setPointTemp + '°C' ?? "-" }}
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex justify-center align-center">
                                    <v-icon small color="redPop">thermostat</v-icon><div class="opacDim mr-1">Max:</div> {{ order.regime?.setPointTemp + '°C' ?? "-" }}
                                </v-col>
                                <v-col cols="12" sm="3" md="3" lg="3" class="d-flex justify-center align-center">
                                    <v-icon small color="secondary">thermostat</v-icon><div class="opacDim mr-1">Min:</div> {{ order.regime?.setPointTemp + '°C' ?? "-" }}
                                </v-col>
                                <v-col cols="12" sm="12" md="12" lg="12" class="d-flex justify-center align-center px-2 pt-2">
                                    <div class="opacDim">{{ order.regime?.recipeDescription }}</div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" class="pt-2 pr-1">
                        <v-card style="height: 100%;" class="infoCards" flat >
                            <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">scale</v-icon>Weights
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="6" lg="6">
                                    <v-list-item>
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.grossWeight ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              Gross Weight
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="mt-0 pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.nettWeight ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Nett Weight
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6">
                                    <v-list-item>
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.tareWeight ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                              Tare
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="mt-0 pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.vgmWeight ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            VGM
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" class="pt-2 pl-1">
                        <v-card style="height: 100%;" class="infoCards" flat >
                            <v-subheader style="height: 30px;" class="px-2">
                                <v-icon small class="mr-2">settings</v-icon>Settings
                            </v-subheader>
                            <v-divider></v-divider>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="6" lg="6">
                                    <v-list-item class="mb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.containerType ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Container Type
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.verificationMethod ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Verification
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="6">
                                    <v-list-item class="mb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.vents ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Vents
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item class="pt-0 pb-0" >
                                        <v-list-item-content class="pl-2">
                                            <v-list-item-title>
                                                {{ container.humidity ?? "-" }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                            Humidity
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" sm="12" md="8" lg="8" class="pl-2" style="height: auto">
                <v-card class="infoCards" flat style="height: 100%" v-if="resultData">
                    <TradingTelemetryChart class="dynamicChart" :data="graphData.series" :key="updateGraphKey" :updateKey="updateGraphKey" :labels="graphData.labels" :textColor="'grey'" />
                </v-card>
                <v-card class="infoCards d-flex align-center justify-center" flat style="height: 100%" v-else>
                    <v-progress-circular indeterminate size="40" color="primary" v-if="loadingData"></v-progress-circular>
                    <span v-if="!loadingData">No Telemetry Data Found</span>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters class="px-2" style="height: 45%;">
            <v-col cols="12" sm="12" md="12" lg="12">
                <v-card class="infoCards" flat style="height: 100%;">
                    <v-subheader style="height: 30px;" class="px-2">
                        <v-icon small class="mr-2">widgets</v-icon>Products
                    </v-subheader>
                    <v-divider></v-divider>
                    <div class="pa-2">
                        <ProductBreakdownTable :dense="true" :noHeader="true" :transparent="true" :headers="productSummaryHeaders" :items="container.productSummary" :tableKey="productKey" :loading="loadingContainerContents" :height="'100%'"/>
                    </div>
                </v-card>
            </v-col>
            <!-- <v-col cols="12" sm="12" md="12" lg="12" class="pt-2">
                <v-card class="infoCards" flat style="height: 100%;">
                    <v-subheader style="height: 30px;" class="px-2">
                        <v-icon small class="mr-2">widgets</v-icon>Stock
                    </v-subheader>
                    <v-divider></v-divider>
                </v-card>
            </v-col> -->
        </v-row>

    </div>
</template>

<script>
import TradingTelemetryChart from '@/components/Charts/TradingTelemetryChart.vue'
import ProductBreakdownTable from "../../SystemComponents/ProductBreakdownTable.vue"
import moment from "moment";
const EVENT_DATE_FORMAT = "DD MMM hh:mm"
import {
    CONTAINER_TRACKING_INTEGRATION_CODES,
    SI_TEST_USERS_IDS
} from '@/utils/constants';
import { color } from 'echarts';
export default {
components: {
    TradingTelemetryChart,
    ProductBreakdownTable
},
props: ['shipment', 'order', 'booking', 'container', 'productSummary', 'documents', 'relationships', 'bookings', 'stackDates', 'loadingOrderContents', 'productSummaryHeaders', 'loadingContainerContents'],
data: () => ({
    productKey: 12,
    resultData: null,
    loadingData: false,
    updateGraphKey: 22,
    containerStatus: [
        { name: 'Allocated', color: '#9d5aa8' },
        { name: 'Unallocated', color: '#dc9f44' },
        { name: 'Ghost', color: 'grey' },
        { name: 'Completed', color: '#4c8d4f' },
        { name: 'Cancelled', color: '#cd584f' }
    ],
    stockStatus: [
      { name: "Allocated", icon: "edit_note", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "#009688" },
      { name: "Complete", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#007d5c" },
      { name: "Not Started", icon: "pending", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
      { name: "Stuffed", icon: "verified", color: "#2196F3" },
    ],
}),

created() {
    if(this.container && this.container.containerNo) {
        this.loadContainerData();
    }
},
watch: {
    container: {
        handler() {
            this.productKey++;
        },
        deep: true
    }
},
computed: {
    averageProgress() {
      let progress = 0
      let progressAllocated = 0
      if(this.container) {
          if(this.container.allocatedProgress) {
            if(typeof this.container.allocatedProgress == 'string') {
              progressAllocated = parseFloat(this.container.allocatedProgress)
            } else {
                progressAllocated = this.container.allocatedProgress
            }
            progressAllocated ? progress += progressAllocated : progress += 0
          }
      } 
      return progress
    },
    loadProgress() {
        let progress = 0
        if(this.container?.loadedPallets && this.container?.allocatedPallets) {
            if(this.container.loadedPallets && this.container.allocatedPallets) {
                progress = (this.container.loadedPallets / this.container.allocatedPallets) * 100
            }
        }
        return progress
    },

    entity() {
        if (!this.resultData || !this.resultData.containerData || Object.keys(this.resultData.containerData.metrics).length === 0) return null;
        return this.resultData.containerData;
    },
    tableData() {
        const dataPoints = [];
        const setValue = (value) => value || '-';
        const bookingSetPoint = {
            min: this.order.regime.minProductTemp,
            max: this.order.regime.maxProductTemp,
            avg: ((this.order.regime.minProductTemp + this.order.regime.maxProductTemp) / 2).toFixed(2)
        }
        if (this.entity?.metrics?.reeferCargoContainerEvents?.length === 0) {
            let bookingTemperatureSetpointDisplay = `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`
            dataPoints.push({
                timestamp: "2024-02-01 05:32:07.000000000",
                dateFormatted: moment("2024-02-01 05:32:07.000000000").format(EVENT_DATE_FORMAT),
                powerEvents: "-",
                returnAirTemperatureDisplay: setValue(null),
                supplyAirTemperatureDisplay: setValue(null),
                temperatureSetpointDisplay: setValue(null),
                bookingTemperatureSetpointDisplay: `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`,
                supplyAirTemperature: {
                    min: 0.0,
                    max: 0.0,
                    avg: 0.0
                },
                returnAirTemperature: {
                    min: 0.0,
                    max: 0.0,
                    avg: 0.0
                },
                temperatureSetpoint: {
                    min: 0.0,
                    max: 0.0,
                    avg: 0.0
                },
                bookingTemperatureSetpoint: bookingSetPoint,
            });
        }
        
        for (const point of this.entity.metrics.reeferCargoContainerEvents) {
            let powerEvents = point.power.length > 0 ? point.power.join(', ') : '-';
            let returnAirTemperatureDisplay = `${setValue(point.returnAir.min)} | ${setValue(point.returnAir.max)} | ${setValue(point.returnAir.avg)}`
            let supplyAirTemperatureDisplay = `${setValue(point.supplyAir.min)} | ${setValue(point.supplyAir.max)} | ${setValue(point.supplyAir.avg)}`
            let temperatureSetpointDisplay = `${setValue(point.setPointTemperature.min)} | ${setValue(point.setPointTemperature.max)} | ${setValue(point.setPointTemperature.avg)}`
            let bookingTemperatureSetpointDisplay = `${setValue(bookingSetPoint.min)} | ${setValue(bookingSetPoint.max)} | ${setValue(bookingSetPoint.avg)}`
            dataPoints.push({
                timestamp: point.timestamp,
                dateFormatted: moment(point.timestamp).format(EVENT_DATE_FORMAT),
                powerEvents,
                returnAirTemperatureDisplay,
                supplyAirTemperatureDisplay,
                temperatureSetpointDisplay,
                bookingTemperatureSetpointDisplay,
                supplyAirTemperature: point.supplyAir,
                returnAirTemperature: point.returnAir,
                temperatureSetpoint: point.setPointTemperature,
                bookingTemperatureSetpoint: bookingSetPoint,
            });
        }
        return dataPoints;
    },
    graphData() {
        const data = this.tableData
        const labels = [];
        const series = {
            supplyAir: {
                data: [],
                type: 'line',
                showInLegend: true,
                color: '#2c9de8',
                name: 'Supply Air',
            },
            returnAir: {
                data: [],
                type: 'line',
                showInLegend: true,
                color: '#007d5c',
                name: 'Return Air',
                smooth: true
            },
            setPoint: {
                data: [],
                type: 'line',
                showInLegend: true,
                color: '#e86123',
                name: 'Set Point'
            },
            bookingSetPoint: {
                data: [],
                type: 'line',
                showInLegend: true,
                color: '#E37169',
                name: `Booking's Set Point`,
                markArea: {
                    data: [
                        [{
                                // name: `Booking's Set Point Range`,
                                yAxis: -0.5,
                                itemStyle: {
                                    color: '#44749c',
                                    opacity: 0.2
                                },
                            },
                            {
                                // name: `Booking's Set Point Range`,
                                yAxis: -0.1
                            }
                        ],
                    ]
                },
            },
            positionEvents: {
                data: [],
                type: 'line',
                silent: true,
                label: {
                    show: false
                },
                showInLegend: false,
                tooltip: {
                    show: false
                },
                markLine: {
                    symbol: ['none', 'none'],
                    data: [],
                    label: {
                        backgroundColor: "#555555",
                        padding: [0, 0],
                        borderRadius: 0,
                        color: "#ffffff",
                        borderWidth: 1,
                        borderColor: "#555555"
                    }
                }
            },

        }
        for (const item of data) {
            labels.push(item.dateFormatted)
            series.positionEvents.data.push(0)
            if (item.positionEvent) {
                series.positionEvents.markLine.data.push({
                    name: item.positionEvent,
                    xAxis: item.dateFormatted,
                    label: {
                        formatter: () => item.positionEvent,
                    }
                })
            } else {
                series.setPoint.data.push(+item.temperatureSetpoint.avg)
                series.returnAir.data.push(+item.returnAirTemperature.avg)
                series.supplyAir.data.push(+item.supplyAirTemperature.avg)
                series.bookingSetPoint.data.push(+item.bookingTemperatureSetpoint.avg)
            }
        }
        if(this.container.containerMilestones?.length > 0) {
            series.containerEvents = {
                data: [],
                type: 'line',
                markLine: {
                    label: {
                        backgroundColor: "#3e3e3e03",
                        padding: [0, 0],
                        borderRadius: 0,
                        color: "#a1a1a1",
                        borderWidth: 1,
                        borderColor: "#3e3e3e03",
                    },
                    lineStyle: {
                        color: '#6d6d6d',
                        type: 'solid',
                        width: 1
                    },
                    data: []
                }
            }
            for(let i = 0; i < this.container.containerMilestones.length; i++) {
                const milestone = this.container.containerMilestones[i];
                if(!milestone.date) continue;
                const milestoneDate = milestone.date ? moment(milestone.date).format(EVENT_DATE_FORMAT) : moment(milestone.createdAt).format(EVENT_DATE_FORMAT);
                const milestoneName = this.$Format.milestoneTypeFormat(milestone.type)
                series.containerEvents.markLine.data.push({
                    name: milestoneName,
                    xAxis: milestoneDate,
                    label: {
                        formatter: () => milestoneName,
                    }
                })
            }
        }
        return {
            series: Object.values(series),
            labels
        }
    },
    },

methods: {
    

    getStatusColor(status, type) {
        let color = 'grey'
      if(type == 'stockStatus') {
        color = this.stockStatus.find(x => x.name == status).color
      } else if(type == 'status'){
        color = this.containerStatus.find(x => x.name == status).color
      } else if(type == 'transportStatus') {
        color = 'grey'
      }
      return color
    },
    async loadContainerData() {
        try {
            this.loadingData = true;
            const containerReference = this.container.containerNo;
            const bookingId = this.container.bookingId;
            const result = await this.$API.getTelemetryBookingContainer(containerReference, bookingId, [CONTAINER_TRACKING_INTEGRATION_CODES.HL]);
            const integrationData = result[CONTAINER_TRACKING_INTEGRATION_CODES.HL];
            this.resultData = {
                containerData: {
                    containerNo: containerReference,
                    metrics: {
                        reeferCargoContainerEvents: integrationData
                    }
                }
            }
            console.log(this.resultData)
            this.updateGraphKey++;
        } catch (e) {
            this.$message.error(e.message || e);
        } finally {
            this.loadingData = false;
        }
    },
}
}

</script>

<style scoped>

* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.innerContent {
    max-height: calc(100vh - 36px - 30px) !important; 
    height: 100%;
    width: 100%;
    contain: content;
    overflow-y: scroll;
}
.v-list-item {
    min-height: 38px;
    height: 38px;
    padding: 0 0px !important;
}

.toolbars ::v-deep .v-toolbar__content {
  padding: 0;
}
.v-list-item__avatar {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
}

.v-list-item__action {
    margin: 0 2px 0 0 !important;
    height: 30px !important;
    min-width: 30px !important;
    width: 30px !important;
    display: flex !important;
    place-content: center !important;
}
.v-list-item__title {
    font-size: 0.75rem !important;
    line-height: 1.4 !important;
}

.v-list-item__subtitle {
    font-size: 0.625rem !important;
    opacity: 0.7 !important;
}

.infoCards {
  background: var(--lighterLayer) !important ; 
  contain: content;
  border-radius: 10px;
}
.opacDim {
    opacity: 0.7;
}
.dynamicChart {
    width: 100%;
}

.v-list-item.pb-0 ::v-deep .v-list-item__content {
    padding-bottom: 0 !important;
}

.v-list-item.pt-0 ::v-deep .v-list-item__content {
    padding-top: 0 !important;
}

.timelineSmall ::v-deep .v-timeline-item__body {
    max-width: calc(100% - 24px) !important;
}
.timelineSmall ::v-deep .v-timeline-item {
    padding-bottom: 4px !important;
}
.timelineSmall ::v-deep .v-timeline-item__divider {
    min-width: 24px !important;
    align-items: center !important;
}
.timelineSmall::before {
    left: 11px !important;
}
.timelineSmall ::v-deep .v-timeline-item__dot, .timelineSmall ::v-deep .v-timeline-item__dot--small {
    height: 12px !important;
    width: 12px !important;
}
</style>