<template>
    <div>
        <div style="display: flex;" class="px-2 pt-2">
            <v-tooltip bottom v-if="shipment.shippingLine">
              <template v-slot:activator="{ on }">
                <v-chip small dense v-on="on" outlined style="border: none" ><!--@click="editVoyage()"-->
                    <v-avatar left color="white" size="24" v-if="shipment.shippingLine">
                      <v-img v-if="shipment.shippingLine && shipment.shippingLine.logo" :src="shipment.shippingLine.logo" contain></v-img>
                    </v-avatar>
                    <v-icon left v-else :color="'grey'">directions_boat</v-icon>
                    <span v-if="shipment.shippingLine">
                        <span v-if="shipment.shippingLine.friendlyName">{{ shipment.shippingLine.friendlyName }}: </span>
                        <span v-else>{{ shipment.shippingLine.name }}: </span>
                    </span>
                  <span v-if="shipment.vessel" class="ml-1">{{ shipment.vessel.name }} {{ shipment.voyage }}</span>
                  <span v-else>No Vessel</span>
                  <span v-if="shipment.vessel.imoNumber" class="ml-1" style="color: grey">{{ shipment.vessel.imoNumber }}</span>
                </v-chip>
              </template>
              <span>Vessel</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-tooltip top>
                <template v-slot:activator={on}>
                    <v-chip outlined style="border: none" small dense v-on="on" class="pl-0 pr-1">
                        <v-avatar size="24" v-if="shipment.voyageInfo.startLocode" class="mr-2">
                            <v-img contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.voyageInfo.startLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                        </v-avatar>
                        <span style="opacity: 0.7;">{{ shipment.voyageInfo.startLocode }}</span>
                    </v-chip>
                </template>
                {{ shipment.voyageInfo.startLocation }} 
            </v-tooltip>
            <v-icon small class="mx-1">arrow_forward</v-icon>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip outlined style="border: none" small dense v-on="on" class="pl-0 pr-1 ml-1">
                        <v-avatar size="24" v-if="shipment.voyageInfo.endLocode" class="mr-2">
                            <v-img contain
                            :src="`https://cdn.loglive.io/flags/4x3/${shipment.voyageInfo.endLocode.substring(0, 2).toLowerCase()}.svg`"></v-img>
                        </v-avatar> 
                        <span style="opacity: 0.7;">{{shipment.voyageInfo.endLocode }}</span>
                    </v-chip>
                </template>
                {{shipment.voyageInfo.endLocation}}
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip outlined style="border: none; font-size: 12px; opacity: 0.7" small dense>{{$Format.calculateDays(shipment.etd, shipment.eta) }} Days</v-chip>
            <v-spacer></v-spacer>
            <v-chip outlined style="border: none; font-size: 12px; opacity: 0.7" small dense>{{ shipment.voyageInfo.legs }} Leg{{shipment.voyageInfo.legs > 1 ? 's' : '' }}</v-chip>
            <v-spacer></v-spacer>
            <v-chip outlined style="border: none" small class="px-0">
                <v-chip :color="shipment.isATD?'success':''" outlined small style="border: none" class="pr-2">
                    <v-icon small left>schedule</v-icon>
                    <span v-if="shipment.isATD" style="font-size: 12px;">ATD: {{$Format.formatDate(shipment.etd).dayMonCommaYear }}</span>
                    <span v-else style="font-size: 12px;">ETD: {{ $Format.formatDate(shipment.etd).dayMonCommaYear}}</span>
                </v-chip>
                <v-icon small class="mx-1">arrow_forward</v-icon>
                <v-chip :color="shipment.isATA?'success':''" outlined small style="border: none" class="pr-2">
                    <v-icon small left >schedule</v-icon>
                    <span v-if="shipment.isATA" style="font-size: 12px;">ATA: {{$Format.formatDate(shipment.eta).dayMonCommaYear }}</span>
                    <span v-else style="font-size: 12px;">ETA: {{ $Format.formatDate(shipment.eta).dayMonCommaYear}}</span>
                </v-chip>
                </v-chip>
        </div>
        <div v-if="shipment.voyageInfo.etd && shipment.voyageInfo.eta"
            class="my-0 py-0">
            <v-slider v-model="shipment.voyageInfo.progress" inverse-label hide-details
                :color="shipment.voyageInfo.progress == 0 ? 'grey' : shipment.voyageInfo.progress < $Format.calculateDays(shipment.etd, shipment.eta) ? 'blue' : 'green'"
                :label="$Format.calculatePercentage(shipment.voyageInfo.progress, $Format.calculateDays(shipment.etd, shipment.eta)) + '%'"
                :thumb-size="16" readonly :min="0"
                :max="$Format.calculateDays(shipment.etd, shipment.eta)">
            </v-slider>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        shipment: {
            type: Object,
            required: true
        }
    },
}
</script>