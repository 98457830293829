<template>
    <div>
        <v-subheader style="font-size: 12px; color: grey; height: 36px;">
            Actions
        </v-subheader>
        <v-divider></v-divider> 
    </div>
</template>

<script>
export default {
    props: [
        'order',
        'relationships',
        'updateKey',
        'orderContents',
        'customerId',
    ],
    data: () => ({
        loading: false,
    }),
}
</script>

<style scoped></style>